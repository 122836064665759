// utils/formatters.js

/**
 * 날짜를 형식화하는 함수
 * @param {string} dateString - 날짜 문자열
 * @returns {string} 형식화된 날짜 문자열
 */
export const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toLocaleString();
    } catch (error) {
      return dateString;
    }
  };
  
  /**
   * enum 타입 문자열에서 값 배열을 추출하는 함수
   * @param {string} type - 필드 타입 문자열 (예: "enum('value1','value2')")
   * @returns {string[]} 추출된 값 배열
   */
  export const parseEnumValues = (type) => {
    const match = type.match(/^enum\((.+)\)$/);
    if (match) {
      return match[1].replace(/'/g, '').split(',');
    }
    return [];
  };
  
  /**
   * 텍스트가 너무 길 경우 잘라내고 '...'를 추가하는 함수
   * @param {string} text - 원본 텍스트
   * @param {number} maxLength - 최대 길이
   * @returns {string} 잘라낸 텍스트
   */
  export const truncateText = (text, maxLength = 100) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };
  
  /**
   * URL이 유효한지 확인하는 함수
   * @param {string} url - 확인할 URL
   * @returns {boolean} 유효 여부
   */
  export const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  
  /**
   * URL에서 도메인을 추출하는 함수
   * @param {string} url - URL 문자열
   * @returns {string|null} 도메인 또는 null (유효하지 않은 URL인 경우)
   */
  export const extractDomain = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.hostname;
    } catch (error) {
      return null;
    }
  };