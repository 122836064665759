// File name : App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown'; // react-markdown 추가
import { signInWithPopup, signInWithRedirect } from 'firebase/auth';
import { auth, googleProvider} from './firebase';
import Intro from './Intro';
import Home from './Home'; // Home 컴포넌트 추가

import HomeBookmark from './HomeBookmark';
import HomeLearnPlay from './HomeLearnPlay';
import HomeUtility from './HomeUtility';
import HomeShared from './HomeShared';

import Diary from './Diary';
import ImageConvert from './ImageConvert';
// import MyMemory from './MyMemory';
import Manage from './Manage';
import HeadsUp from './HeadsUp';
import HeadsUpBasic from './HeadsUpBasic'; 
import WordStudyToeic from './WordStudyToeic'; 
import WordStudyGRE from './WordStudyGRE'; 
import WordStudySAT from './WordStudySAT'; 
import WordStudyHS from './WordStudyHS';  

import WaterMark from './WaterMark'; 
import Clipboard from './Clipboard';
import ScanPage from './ScanPage';
import Homehuggingface from './Homehuggingface';

import SpeechToText from './SpeechToText'; 

// import WordStudySAT_rand from './WordStudySAT_rand'; 


import './styles-global.css';
import './App.css'; 


function App() {
  const [webpageData, setWebpageData] = useState([]);
  const [sloganText, setSloganText] = useState(''); // Slogan text 상태 추가 

  // const [menuItems, setMenuItems] = useState([]); 
  // const [currentIndex, setCurrentIndex] = useState(-1);
  // const [selectedItem, setSelectedItem] = useState(null);
  // const [showDetails, setShowDetails] = useState(false);
  // const [viewFile, setViewFile] = useState(false);
  // const [animationFinished, setAnimationFinished] = useState(false); 
  // const [isHovered, setIsHovered] = useState(false); 
  // const [isTouching, setIsTouching] = useState(false); 
  // const [isAnimating, setIsAnimating] = useState(false); 
  const [user, setUser] = useState(null); // 로그인된 사용자 상태 추가 
  const storedLanguage = localStorage.getItem('language'); // 저장된 language 읽기
  const [language, setLanguage] = useState('KR'); // 기본값은 EN
  const [userIp, setUserIp] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [countryCode, setCountryCode] = useState('');


  // let SERVER_ROOT = 'https://www.doldari.com:3001';

  // const LOCAL_MODE = true;

  // if (LOCAL_MODE) {
  //   SERVER_ROOT = 'http://localhost:3001';
  // } 

  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001'; 

  // 모바일 감지 함수
  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  // Google Provider에 매번 계정을 선택하도록 설정
  googleProvider.setCustomParameters({ prompt: 'select_account' });

 
  // 국가 정보를 가져와 language 설정
  useEffect(() => {
    const fetchCountryInfo = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const detectedCountryCode = response.data.country_code;
        setCountryCode(detectedCountryCode);

        if (!storedLanguage) {
          setLanguage(detectedCountryCode === "KR" ? "KR" : "EN");
        }

        updateUserCountryAndVisits(detectedCountryCode);
      } catch (error) { 
        console.error("Error fetching country information:", error);
      }
    }; 

    fetchCountryInfo();
  }, []);

  const updateUserCountryAndVisits = async (detectedCountryCode) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("user"));

      if (storedUser) {
        const updatedUser = {
          uid: storedUser.uid,
          countryCode: detectedCountryCode,
        };

        await axios.put(`${SERVER_ROOT}/api/users/visit`, updatedUser);

        console.log("User visit updated successfully.");
      } else {
        console.warn("User is not logged in.");
      }
    } catch (error) {
      console.error("Error updating user country and visits:", error);
    }
  };

  useEffect(() => {
      axios.get(`${SERVER_ROOT}/api/userip`) // Replace SERVER_ROOT with your server URL
          .then((response) => {
              console.log('Fetched IP:', response.data.ip); // Debug log
              setUserIp(response.data.ip); 
          })
          .catch((error) => {
              console.error('Error fetching IP address:', error);
          });
  }, []);

  const checkAndCreateUser = async () => {
    const storedUser = JSON.parse(localStorage.getItem('uk_user'));

    if (!storedUser) {
      const newUser = createTemporaryUser();

      try {
        // 서버에 사용자 데이터 저장
        await axios.post(`${SERVER_ROOT}/api/users`, newUser);

        setUser(newUser);
        localStorage.setItem('uk_user', JSON.stringify(newUser));
        console.log('Temporary user created:', newUser);
      } catch (error) {
        console.error('Error creating user:', error);
      }
    } else {
      console.log('Logged-in user:', storedUser);
      setUser(storedUser);
    }
  };

  useEffect(() => { 
    checkAndCreateUser(); // 비동기 함수 호출
  }, []);

  // Temporary User 생성 함수
  const createTemporaryUser = () => {
    const uid = generateUid();
    const displayName = uid;
    const email = `${uid}@doldari.com`;

    return {
      uid,
      displayName,
      email,
      profile_picture_url: '/images/uk.png',
      subscription: 'free', 
    };
  };

  // UID 생성 함수 (Google UID와 유사한 방식, 'uk_' 접두사 추가)
  const generateUid = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 28; // Google UID와 동일한 길이
    let randomUid = 'uk_';
    for (let i = 0; i < length; i++) {
      randomUid += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return randomUid;
  };

  useEffect(() => {
    console.log('Current user:', user);
  }, [user]);

  // Check localStorage for a user on initial load
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser); // Restore the user from localStorage if found
    }
  }, []); 

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
  
      const loggedInUser = {
        uid: result.user.uid,
        displayName: result.user.displayName,
        email: result.user.email, 
        profile_picture_url: result.user.photoURL,
        subscription: 'free' // 기본값
      };
  
      // 서버에 사용자 데이터 저장
      await axios.post(`${SERVER_ROOT}/api/users`, loggedInUser);
  
      setUser(loggedInUser);
      localStorage.setItem('user', JSON.stringify(loggedInUser));
    } catch (error) {
      console.error('Google Login Error:', error);
    }
  };
  

  const handleNaverLogin = () => {
    alert('Naver login is currently under development.'); 
  };

  // Function to handle logout
  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      localStorage.removeItem('user'); // Remove user from localStorage on logout

      checkAndCreateUser(); // 비동기 함수 호출
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const handleLanguageToggle = () => {
    setLanguage((prevLanguage) => {
      const newLanguage = prevLanguage === 'KR' ? 'EN' : 'KR';
      localStorage.setItem('language', newLanguage); // localStorage에 저장
      return newLanguage;
    });
  }; 
  

  const getValueByName = (dataArray, name) => {
    const record = dataArray.find(record => record.name === name);
    return record ? record.value : null; // name이 없을 경우 null 반환
  };
  
  useEffect(() => { 
    const tableName = language !== 'KR' ? 'webpage_EN' : 'webpage';

    console.log(tableName);

    axios.get(`${SERVER_ROOT}/api/${tableName}`) 
      .then(response => {
        console.log(`Fetched ${tableName} data:`, response.data); // 데이터 출력
        setWebpageData(response.data);

        console.log(webpageData);

        // name이 'App.js_slogun'인 record 찾기
        const sloganRecord = response.data.find(item => item.name === 'App.js_slogun');
        if (sloganRecord) {
          setSloganText(sloganRecord.value); // 상태 업데이트
        } 
      })
      .catch(error => {
        console.error("Error fetching webpage data:", error);
      });
  }, [language]);


  

  return ( 
    <Router>
      <div className="App">
        <div className="topmenu">  
          <Link to="/">
            <img src="/resources/newlogo_doldari3.png" alt="Doldari" className="logo" />
          </Link>
          {/* <Link to="/imageconvert">
            <img src="/images/imageconvert.png" alt="ImageConvert" className="logo" />
          </Link> */}
          {/* <img src="resources/q2-2-removebg-preview.png" alt="TG Abyss Logo" class="logo"></img> */}

          {/* <div>Your IP Address: {userIp}</div>; */}
          {/* <Link to="/diary">
            <img src="/resources/myDiary.png" alt="Doldari" className="logo" />
          </Link> */}

          {/* <Link to="/toeicwordstudy"> 
          <img src="/resources/logo-doldari2.png" alt="Doldari" className="logo" />
          </Link> */}

          {/* <Link to="/scanPage"> Scan </Link> */}
          {/* <Link to="/SpeechToText"> Extract Text </Link> */}

          <button onClick={handleLanguageToggle} className="language-button">
            {language === 'KR' ? 'ENGLISH' : '한국어'}
          </button>  



          {user && !user.uid.startsWith('uk_') ? (
            <div className="user-section">
              
              <button className='logout-button' onClick={handleLogout}> {language === 'KR' ? '로그아웃' : 'Logout'} </button>
              {user?.profile_picture_url && (
                <div className="profile-container">
                  {/* <img
                    src={user.profile_picture_url}
                    alt="User Profile"
                    className="user-profile-picture"
                  /> */}
                  <span className="user-display-name">{user.displayName}</span>
                </div>
              )}
            </div>
          ) : (
            <div>
              <button onClick={handleGoogleLogin} className="googleLogin-button">
                {language === 'KR' ? '구글 로그인' : 'Google Login'}
              </button>
              {/* {language === 'KR' ? (
                <button onClick={handleNaverLogin} className="naverLogin-button">
                  네이버 로그인
                </button>
              ) : null} */}
            </div>
             
          )}  
              
        </div>
        {/* <div>
          <header className="App-header"> */}
            
        <div className="content">    
          <Routes>
            <Route path="/" element={<Home language={language} user={user} countryCode={countryCode} userIp={userIp}/>} />

            <Route path="/homeBookmark" element={<HomeBookmark language={language} user={user}/>} />
            <Route path="/homeLearnPlay" element={<HomeLearnPlay language={language} user={user}/>} />
            <Route path="/homeUtility" element={<HomeUtility language={language} user={user}/>} />
            <Route path="/homeShared" element={<HomeShared language={language} user={user}/>} />

            <Route path="/intro" element={<Intro language={language} />} />
            <Route path="/diary" element={<Diary language={language} user={user}/>} />
            <Route path="/Imageconvert" element={<ImageConvert language={language} user={user} countryCode={countryCode} userIp={userIp}/>} />
            {/* <Route path="/myMemory" element={<MyMemory language={language} />} /> */}
            <Route path="/manage" element={<Manage language={language} user={user}/>} />
            <Route path="/headsup" element={<HeadsUp language={language} user={user}/>} /> 

            <Route path="/headsupbasic" element={<HeadsUpBasic language={language} user={user}/>} />

            <Route path="/wordstudytoeic" element={<WordStudyToeic language={language} user={user} countryCode={countryCode} userIp={userIp}/>} />  
            <Route path="/wordstudygre" element={<WordStudyGRE language={language} user={user}/>} countryCode={countryCode} userIp={userIp}/>  
            <Route path="/wordstudysat" element={<WordStudySAT language={language} user={user}/>} countryCode={countryCode} userIp={userIp}/>  
            <Route path="/wordstudyhs" element={<WordStudyHS language={language} user={user}/>} countryCode={countryCode} userIp={userIp}/> 

            <Route path="/clipboard" element={<Clipboard language={language} user={user} countryCode={countryCode} userIp={userIp}/>} />
            <Route path="/watermark" element={<WaterMark language={language} user={user} countryCode={countryCode} userIp={userIp}/>} /> 
            <Route path="/scanPage" element={<ScanPage language={language} user={user}/>} /> 
            <Route path="/homehuggingface" element={<Homehuggingface language={language} user={user}/>} /> 

            <Route path="/speechtotext" element={<SpeechToText language={language} user={user}/>} /> 
            
            {/* <Route path="/wordstudysat_rand" element={<WordStudySAT_rand language={language} user={user}/>} /> */}
          </Routes> 
        </div>

        
          {/* </header>
          
        </div> */}
      </div>  
    </Router>  
  );
}

export default App;
