// components/visit/VisitStatsTab.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSync, FaChartBar, FaCalendarAlt, FaHome, FaSearch, FaFileExport } from 'react-icons/fa';
import { formatDate } from '../../utils/formatters';
import './VisitStatsTab.css';
import VisitStatsExport from './VisitStatsExport';

/**
 * 방문자 통계 데이터를 표시하는 탭 컴포넌트
 * @param {Object} props - 컴포넌트 props
 * @param {string} props.language - 언어 설정
 * @param {string} props.userEmail - 사용자 이메일
 * @param {string} props.SERVER_ROOT - 서버 URL
 * @param {Function} props.setIsLoading - 로딩 상태 설정 함수
 * @param {Function} props.setStatusMessage - 상태 메시지 설정 함수
 * @param {Function} props.onSelectPage - 페이지 선택 핸들러 함수
 */
function VisitStatsTab({ 
  language, 
  userEmail, 
  SERVER_ROOT, 
  setIsLoading, 
  setStatusMessage,
  onSelectPage
}) {
  const [statsData, setStatsData] = useState([]);
  const [period, setPeriod] = useState('summary');
  const [selectedPage, setSelectedPage] = useState('');
  const [availablePages, setAvailablePages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStats, setFilteredStats] = useState([]);
  const [showExport, setShowExport] = useState(false);

  // 첫 로드 시 방문자 통계 자동 조회
  useEffect(() => {
    fetchVisitStats();
  }, [period, selectedPage]);

  // 검색어 필터링
  useEffect(() => {
    if (statsData.length > 0) {
      if (searchTerm.trim() === '') {
        setFilteredStats(statsData);
      } else {
        const filtered = statsData.filter(item => 
          (item.page_name && item.page_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.month && item.month.includes(searchTerm)) ||
          (item.visit_date && item.visit_date.includes(searchTerm))
        );
        setFilteredStats(filtered);
      }
    } else {
      setFilteredStats([]);
    }
  }, [searchTerm, statsData]);

  // 통계 데이터 가져오기
  const fetchVisitStats = () => {
    setIsLoading(true);
    setStatusMessage(language === 'KR' ? '방문자 통계를 불러오는 중...' : 'Loading visit statistics...');
    
    axios
      .get(`${SERVER_ROOT}/api/visit-stats`, {
        params: { 
          userEmail: userEmail,
          period: period,
          page_name: selectedPage || undefined
        },
      })
      .then((response) => {
        setStatsData(response.data);
        setFilteredStats(response.data);
        
        // 사용 가능한 페이지 목록 추출 (첫 로드 시 summary에서만)
        if (period === 'summary' && !selectedPage) {
          const pages = response.data.map(item => item.page_name);
          setAvailablePages(pages);
        }
        
        setStatusMessage('');
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching visit statistics:', error);
        setStatusMessage(language === 'KR' 
          ? '통계 데이터를 불러오는데 실패했습니다: ' + error.message 
          : 'Failed to load statistics: ' + error.message);
        setIsLoading(false);
      });
  };

  // 기간 변경 핸들러
  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
  };

  // 페이지 필터 변경 핸들러
  const handlePageChange = (e) => {
    setSelectedPage(e.target.value);
  };

  // 페이지 선택 핸들러 (페이지 상세 통계로 이동)
  const handlePageSelect = (pageName) => {
    if (onSelectPage) {
      onSelectPage(pageName);
    }
  };

  // 내보내기 토글 핸들러
  const toggleExport = () => {
    setShowExport(!showExport);
  };

  // 날짜와 시간을 함께 포맷팅하는 함수
  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return '';
    
    try {
      const date = new Date(dateTimeStr);
      if (isNaN(date.getTime())) return dateTimeStr;
      
      return new Intl.DateTimeFormat('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Asia/Seoul'
      }).format(date);
    } catch (error) {
      console.error('Error formatting date/time:', error);
      return dateTimeStr;
    }
  };

  // 데이터 테이블 렌더링 함수
  const renderDataTable = () => {
    if (filteredStats.length === 0) {
      return (
        <div className="no-data-message">
          {language === 'KR' ? '방문자 통계 데이터가 없습니다.' : 'No visit statistics available.'}
        </div>
      );
    }

    // 기간별 다른 테이블 구조 렌더링
    switch (period) {
      case 'daily':
        return (
          <table className="visit-stats-table">
            <thead>
              <tr>
                <th>{language === 'KR' ? '날짜' : 'Date'}</th>
                <th>{language === 'KR' ? '페이지' : 'Page'}</th>
                <th>{language === 'KR' ? '총 방문수' : 'Total Visits'}</th>
                <th>{language === 'KR' ? '고유 방문자' : 'Unique Visitors'}</th>
                <th>{language === 'KR' ? '고유 세션' : 'Unique Sessions'}</th>
                <th>{language === 'KR' ? '상세' : 'Details'}</th>
              </tr>
            </thead>
            <tbody>
              {filteredStats.map((row, index) => (
                <tr key={index}>
                  <td>{formatDate(row.visit_date)}</td>
                  <td>{row.page_name}</td>
                  <td>{row.total_visits}</td>
                  <td>{row.unique_visitors}</td>
                  <td>{row.unique_sessions}</td>
                  <td>
                    <button 
                      className="view-details-button"
                      onClick={() => handlePageSelect(row.page_name)}
                    >
                      {language === 'KR' ? '보기' : 'View'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
        
      case 'monthly':
        return (
          <table className="visit-stats-table">
            <thead>
              <tr>
                <th>{language === 'KR' ? '월' : 'Month'}</th>
                <th>{language === 'KR' ? '페이지' : 'Page'}</th>
                <th>{language === 'KR' ? '총 방문수' : 'Total Visits'}</th>
                <th>{language === 'KR' ? '고유 방문자' : 'Unique Visitors'}</th>
                <th>{language === 'KR' ? '고유 세션' : 'Unique Sessions'}</th>
                <th>{language === 'KR' ? '상세' : 'Details'}</th>
              </tr>
            </thead>
            <tbody>
              {filteredStats.map((row, index) => (
                <tr key={index}>
                  <td>{row.month}</td>
                  <td>{row.page_name}</td>
                  <td>{row.total_visits}</td>
                  <td>{row.unique_visitors}</td>
                  <td>{row.unique_sessions}</td>
                  <td>
                    <button 
                      className="view-details-button"
                      onClick={() => handlePageSelect(row.page_name)}
                    >
                      {language === 'KR' ? '보기' : 'View'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
        
      case 'summary':
      default:
        return (
          <table className="visit-stats-table">
            <thead>
              <tr>
                <th>{language === 'KR' ? '페이지' : 'Page'}</th>
                <th>{language === 'KR' ? '총 방문수' : 'Total Visits'}</th>
                <th>{language === 'KR' ? '고유 방문자' : 'Unique Visitors'}</th>
                <th>{language === 'KR' ? '고유 세션' : 'Unique Sessions'}</th>
                <th>{language === 'KR' ? '방문 일수' : 'Visit Days'}</th>
                <th>{language === 'KR' ? '첫 방문' : 'First Visit'}</th>
                <th>{language === 'KR' ? '최근 방문' : 'Last Visit'}</th>
                <th>{language === 'KR' ? '상세' : 'Details'}</th>
              </tr>
            </thead>
            <tbody>
              {filteredStats.map((row, index) => (
                <tr key={index}>
                  <td>{row.page_name}</td>
                  <td>{row.total_visits}</td>
                  <td>{row.unique_visitors}</td>
                  <td>{row.unique_sessions}</td>
                  <td>{row.visit_days}</td>
                  <td>{formatDateTime(row.first_visit)}</td>
                  <td>{formatDateTime(row.last_visit)}</td>
                  <td>
                    <button 
                      className="view-details-button"
                      onClick={() => handlePageSelect(row.page_name)}
                    >
                      {language === 'KR' ? '보기' : 'View'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
    }
  };

  return (
    <div className="visitstats">
      <div className="filter-bar">
        <div className="period-buttons">
          <button 
            className={period === 'summary' ? 'active' : ''}
            onClick={() => handlePeriodChange('summary')}
          >
            <FaChartBar />
            {language === 'KR' ? ' 요약' : ' Summary'}
          </button>
          <button 
            className={period === 'daily' ? 'active' : ''}
            onClick={() => handlePeriodChange('daily')}
          >
            <FaCalendarAlt />
            {language === 'KR' ? ' 일별' : ' Daily'}
          </button>
          <button 
            className={period === 'monthly' ? 'active' : ''}
            onClick={() => handlePeriodChange('monthly')}
          >
            <FaCalendarAlt />
            {language === 'KR' ? ' 월별' : ' Monthly'}
          </button>
        </div>
        
        <div className="filter-controls">
          <div className="page-filter">
            <select 
              id="page-select"
              value={selectedPage}
              onChange={handlePageChange}
              className="page-select"
            >
              <option value="">{language === 'KR' ? '모든 페이지' : 'All Pages'}</option>
              {availablePages.map(page => (
                <option key={page} value={page}>{page}</option>
              ))}
            </select>
          </div>
          
          <div className="search-box">
            <input
              type="text"
              placeholder={language === 'KR' ? '      검색...' : '      Search...'}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <FaSearch className="search-icon" />
          </div>
          
          <button 
            className="refresh-button"
            onClick={fetchVisitStats}
          >
            <FaSync />
            {language === 'KR' ? ' 새로고침' : ' Refresh'}
          </button>
          
          <button 
            className="export-toggle-button"
            onClick={toggleExport}
          >
            <FaFileExport />
            {language === 'KR' ? ' 내보내기' : ' Export'}
          </button>
        </div>
      </div>
      
      {showExport && (
        <div className="export-section">
          <VisitStatsExport
            language={language}
            SERVER_ROOT={SERVER_ROOT}
            pageName={selectedPage || null}
            period={period}
            onSuccess={() => setStatusMessage(
              language === 'KR' ? '데이터가 성공적으로 내보내졌습니다.' : 'Data exported successfully.'
            )}
            onError={(errorMsg) => setStatusMessage(
              language === 'KR' ? '내보내기 오류: ' + errorMsg : 'Export error: ' + errorMsg
            )}
          />
        </div>
      )}
      
      <div className="stats-summary">
        <div className="stats-count">
          {language === 'KR' 
            ? `총 ${filteredStats.length}개의 레코드 표시 중` 
            : `Showing ${filteredStats.length} records`}
        </div>
      </div>
      
      <div className="table-container">
        {renderDataTable()}
      </div>
    </div>
  );
}

export default VisitStatsTab;