// components/manage/VisitCountTab.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSync } from 'react-icons/fa';
import { formatDate } from '../../utils/formatters';

function VisitCountTab({ language, userEmail, SERVER_ROOT, setIsLoading, setStatusMessage }) {
  const [visitData, setVisitData] = useState([]);

  // 첫 로드 시 방문자 수 데이터 자동 조회
  useEffect(() => {
    fetchVisitCounts();
  }, []);

  const fetchVisitCounts = () => {
    setIsLoading(true);
    setStatusMessage('데이터를 불러오는 중...');
    
    axios
      .get(`${SERVER_ROOT}/api/visitCounts`, {
        params: { userEmail: userEmail },
      })
      .then((response) => {
        // 데이터 정렬: 최근 날짜와 방문 수 기준 내림차순
        const sortedData = response.data.sort((a, b) => {
          const dateA = new Date(a.lastVisitDateTime || 0);
          const dateB = new Date(b.lastVisitDateTime || 0);
  
          if (dateB - dateA !== 0) {
            // 날짜 기준으로 정렬
            return dateB - dateA;
          }
          // 방문 수 기준으로 정렬
          return b.visitCount - a.visitCount;
        });
  
        setVisitData(sortedData);
        setStatusMessage('');
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching visit counts:', error);
        setStatusMessage('데이터를 불러오는데 실패했습니다: ' + error.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="visitcount">
      <div className="action-bar">
        <button 
          className="visit-count-button" 
          onClick={fetchVisitCounts}
          disabled={false}
        >
          <FaSync />
          {language === 'KR' ? ' 방문 수 새로고침' : ' Refresh Visit Count'}
        </button>
      </div>
      
      {visitData.length > 0 ? (
        <div className="table-container">
          <table className="visit-count-table">
            <thead>
              <tr>
                <th>{language === 'KR' ? '테이블 이름' : 'Table Name'}</th>
                <th >Visited URL</th>
                <th style={{ textAlign: 'center' }}>{language === 'KR' ? '방문 수' : 'Visit Count'}</th>
                <th style={{ textAlign: 'center' }}>{language === 'KR' ? '최근 날짜' : 'Latest Date/Time'}</th>
              </tr>
            </thead>
            <tbody>
              {visitData.map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: 'left' }}>{row.table_name}</td>
                  <td style={{ textAlign: 'left' }}>
                    <a href={row.url} target="_blank" rel="noopener noreferrer" className="url-link">
                      {row.url.length > 50 ? row.url.substring(0, 50) + '...' : row.url}
                    </a>
                  </td>
                  <td style={{ textAlign: 'center' }}>{row.visitCount}</td>
                  <td style={{ textAlign: 'center' }}>{formatDate(row.lastVisitDateTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-data-message">
          {language === 'KR' ? '방문 데이터가 없습니다.' : 'No visit data available.'}
        </div>
      )}
    </div>
  );
}

export default VisitCountTab;