// components/common/Pagination.js
import React, { useEffect, useState } from 'react';
import {FaChevronLeft, FaChevronRight, FaAngleDoubleLeft, FaAngleDoubleRight} from 'react-icons/fa';
import './Pagination.css';

function Pagination({ currentPage, totalPages, onPageChange, language }) {
  const [isMobile, setIsMobile] = useState(false);
  const [maxVisibleButtons, setMaxVisibleButtons] = useState(7); // 기본값

  // 윈도우 크기에 따라 모바일 여부 및 버튼 개수 설정
  useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setMaxVisibleButtons(mobile ? 1 : 5); // 모바일은 3개, 데스크톱은 7개
    };
    
    checkMobile(); // 초기 확인
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  // 페이지 번호 배열 생성 로직 
  const getPageNumbers = () => {
    const pages = [];
    
    if (isMobile) { 
        pages.push(currentPage); 
    }
    else {
        // 최대 5개의 페이지 버튼 표시
        if (totalPages <= maxVisibleButtons) {
        // 전체 페이지가 5개 이하면, 모두 표시
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        } else {
        // 전체 페이지가 5개 초과일 때, 현재 페이지 주변의 페이지만 표시
        if (currentPage <= 5) {
            // 현재 페이지가 3 이하이면, 1~5 표시
            for (let i = 1; i <= maxVisibleButtons; i++) {
            pages.push(i);
            }
        } else if (currentPage >= totalPages - 4) {
            // 현재 페이지가 끝에서 3번째 이하이면, 마지막 5개 표시
            for (let i = totalPages - 4; i <= totalPages; i++) {
            pages.push(i);
            }
        } else {
            // 그 외의 경우, 현재 페이지 기준 앞뒤로 2개씩 표시
            for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pages.push(i);
            }
        }
        }
    }
    
    return pages;
  };

  return (
    <div className="pagination">
      <button 
        onClick={() => onPageChange(1)} 
        disabled={currentPage === 1}
        className="pagination-button"
        aria-label={language === 'KR' ? '첫 페이지로' : 'Go to first page'}
      >
        {/* <FaAngleDoubleLeft  size={16} /> */}
        <span className="pagination-icon">&#171;</span>
      </button>
      <button 
        onClick={() => onPageChange(currentPage - 1)} 
        disabled={currentPage === 1}
        className="pagination-button"
        aria-label={language === 'KR' ? '이전 페이지' : 'Previous page'}
      >
        {/* <FaChevronLeft size={16}  /> */}
        <span className="pagination-icon">&#8249;</span>
      </button>
      
      <div className="page-numbers">
        {getPageNumbers().map(pageNumber => (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            className={`pagination-number ${currentPage === pageNumber ? 'active' : ''}`}
            aria-label={`${language === 'KR' ? '페이지' : 'Page'} ${pageNumber}`}
            aria-current={currentPage === pageNumber ? 'page' : null}
          >
            {pageNumber}
          </button>
        ))}
      </div>
      
      <button 
        onClick={() => onPageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
        className="pagination-button"
        aria-label={language === 'KR' ? '다음 페이지' : 'Next page'}
      >
        {/* <FaChevronRight size={16} /> */}
        <span className="pagination-icon">&#8250;</span>
      </button>
      <button 
        onClick={() => onPageChange(totalPages)} 
        disabled={currentPage === totalPages}
        className="pagination-button"
        aria-label={language === 'KR' ? '마지막 페이지로' : 'Go to last page'}
      >
        {/* <FaAngleDoubleRight size={16}/> */}
        <span className="pagination-icon">&#187;</span>
      </button>
    </div>
  );
}

export default Pagination;