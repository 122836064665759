import axios from 'axios';
import { useEffect } from 'react';

/**
 * 페이지 방문을 기록하는 유틸리티 함수
 * @param {string} pageName - 페이지 이름 (예: 'Home', 'Clipboard')
 * @param {object} options - 방문 정보 옵션
 * @returns {Promise<void>}
 */
export const recordPageVisit = async (pageName, options) => {
  const {
    userId,
    userIp,
    countryCode,
    SERVER_ROOT,
    language,
    isMobileDevice
  } = options;

  try {
    // 세션 ID 생성 또는 가져오기
    let sessionId = sessionStorage.getItem('visitSessionId');
    if (!sessionId) {
      sessionId = 'session_' + Math.random().toString(36).substring(2, 15) + 
                  Math.random().toString(36).substring(2, 15);
      sessionStorage.setItem('visitSessionId', sessionId);
    }
    
    // 방문 중복 방지 로직 추가
    const lastVisitTimeKey = `last${pageName}VisitTime`;
    const lastVisitTime = localStorage.getItem(lastVisitTimeKey);
    const currentTime = new Date().getTime();
    
    // 5초 이상 머문 경우만 click으로 count
    if (lastVisitTime && (currentTime - parseInt(lastVisitTime)) < (5 * 1000)) {
      console.log(`Recent visit to ${pageName} detected, not recording duplicate visit`);
      return;
    }

    // browser 변수를 수정하여 전체 userAgent 대신 주요 브라우저 이름만 추출
    let browser = "Unknown";
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf("Firefox") > -1) {
      browser = "Firefox";
    } else if (userAgent.indexOf("Edge") > -1) {
      browser = "Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browser = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browser = "Safari";
    }
    
    await axios.post(`${SERVER_ROOT}/api/record-visit`, {
      page_name: pageName,
      user_id: userId,
      ip_address: userIp,
      country_code: countryCode,
      session_id: sessionId,
      referrer: document.referrer,
      browser: browser,
      device_type: isMobileDevice ? 'Mobile' : 'Desktop',
      language
    });
    
    localStorage.setItem(lastVisitTimeKey, currentTime.toString());
    console.log(`Page visit to ${pageName} recorded successfully`);
  } catch (error) {
    console.error(`Error recording page visit to ${pageName}:`, error);
  }
};

/**
 * 페이지 방문 훅을 설정하는 함수
 * React 컴포넌트 내에서 사용
 * @param {string} pageName - 페이지 이름
 * @param {object} options - 방문 정보 옵션
 */
export const usePageVisitTracker = (pageName, options) => {
  useEffect(() => {
    recordPageVisit(pageName, options);
  }, []); // 빈 의존성 배열로 한 번만 실행
};