// components/visit/PageStatsDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaChartBar, FaUsers, FaCalendarDay, FaGlobe, FaMobileAlt, FaLaptop } from 'react-icons/fa';
import { formatDate } from '../../utils/formatters';
import './PageStatsDashboard.css';

/**
 * 페이지별 방문 통계 대시보드 컴포넌트
 * 선택한 페이지의 상세 방문 통계를 시각화
 */
const PageStatsDashboard = ({ language, userEmail, SERVER_ROOT, pageName, setIsLoading, setStatusMessage }) => {
  const [summaryStats, setSummaryStats] = useState(null);
  const [deviceStats, setDeviceStats] = useState([]);
  const [countryStats, setCountryStats] = useState([]);
  const [dailyStats, setDailyStats] = useState([]);
  
  // 선택한 페이지의 통계 데이터 로드
  useEffect(() => {
    if (!pageName) return;
    
    const fetchPageStats = async () => {
      setIsLoading(true);
      setStatusMessage(language === 'KR' 
        ? `${pageName} 페이지 통계를 불러오는 중...` 
        : `Loading statistics for ${pageName}...`);
      
      try {
        // 요약 통계 가져오기
        const summaryResponse = await axios.get(`${SERVER_ROOT}/api/visit-stats`, {
          params: { 
            userEmail,
            period: 'summary',
            page_name: pageName
          }
        });
        
        if (summaryResponse.data && summaryResponse.data.length > 0) {
          setSummaryStats(summaryResponse.data[0]);
        }
        
        // 기기별 통계 가져오기 (서버 API 추가 필요)
        const deviceResponse = await axios.get(`${SERVER_ROOT}/api/visit-stats-by-device`, {
          params: { 
            userEmail,
            page_name: pageName
          }
        });
        
        setDeviceStats(deviceResponse.data || []);
        
        // 국가별 통계 가져오기 (서버 API 추가 필요)
        const countryResponse = await axios.get(`${SERVER_ROOT}/api/visit-stats-by-country`, {
          params: { 
            userEmail,
            page_name: pageName
          }
        });
        
        setCountryStats(countryResponse.data || []);
        
        // 일별 통계 가져오기
        const dailyResponse = await axios.get(`${SERVER_ROOT}/api/visit-stats`, {
          params: { 
            userEmail,
            period: 'daily',
            page_name: pageName
          }
        });
        
        // 최근 14일 데이터만 사용
        setDailyStats(dailyResponse.data.slice(0, 14) || []);
        
        setStatusMessage('');
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching page statistics:', error);
        setStatusMessage(language === 'KR'
          ? '통계 데이터를 불러오는데 실패했습니다: ' + error.message
          : 'Failed to load statistics: ' + error.message);
        setIsLoading(false);
      }
    };
    
    fetchPageStats();
  }, [pageName, userEmail, SERVER_ROOT, language, setIsLoading, setStatusMessage]);
  
  // 통계가 없는 경우
  if (!summaryStats) {
    return (
      <div className="no-stats-message">
        {language === 'KR' 
          ? '선택한 페이지의 방문 통계가 없습니다.' 
          : 'No visit statistics available for the selected page.'}
      </div>
    );
  }
  
  return (
    <div className="page-stats-dashboard">
      <div className="page-title">
        <h3>{pageName}</h3>
        <p className="date-range">
          {formatDate(summaryStats.first_visit)} ~ {formatDate(summaryStats.last_visit)}
        </p>
      </div>
      
      <div className="stats-summary">
        <div className="stats-card">
          <FaUsers className="stats-icon" />
          <div className="stats-number">{summaryStats.total_visits.toLocaleString()}</div>
          <div className="stats-label">{language === 'KR' ? '총 방문수' : 'Total Visits'}</div>
        </div>
        
        <div className="stats-card">
          <FaUsers className="stats-icon" />
          <div className="stats-number">{summaryStats.unique_visitors.toLocaleString()}</div>
          <div className="stats-label">{language === 'KR' ? '고유 방문자' : 'Unique Visitors'}</div>
        </div>
        
        <div className="stats-card">
          <FaCalendarDay className="stats-icon" />
          <div className="stats-number">{summaryStats.visit_days.toLocaleString()}</div>
          <div className="stats-label">{language === 'KR' ? '방문 일수' : 'Days Visited'}</div>
        </div>
      </div>
      
      <div className="stats-detail-container">
        {/* 일별 방문 그래프 */}
        <div className="stats-detail-card">
          <h4>
            <FaChartBar />
            {language === 'KR' ? ' 일별 방문 추이' : ' Daily Visits'}
          </h4>
          <div className="daily-chart">
            {dailyStats.map((day, index) => (
              <div key={index} className="chart-bar-container">
                <div 
                  className="chart-bar" 
                  style={{ 
                    height: `${(day.total_visits / Math.max(...dailyStats.map(d => d.total_visits))) * 100}%` 
                  }}
                >
                  <span className="bar-value">{day.total_visits}</span>
                </div>
                <div className="chart-label">{formatDate(day.visit_date).substring(5)}</div>
              </div>
            ))}
          </div>
        </div>
        
        {/* 디바이스별 방문 통계 */}
        <div className="stats-detail-card">
          <h4>
            <FaMobileAlt />
            {language === 'KR' ? ' 디바이스별 방문' : ' Visits by Device'}
          </h4>
          <div className="stats-pie-chart">
            {deviceStats.map((device, index) => (
              <div key={index} className="pie-segment">
                <div className="segment-color" style={{ backgroundColor: getDeviceColor(device.device_type) }}></div>
                <div className="segment-label">{device.device_type}</div>
                <div className="segment-value">{device.count} ({calculatePercentage(device.count, deviceStats)}%)</div>
              </div>
            ))}
          </div>
        </div>
        
        {/* 국가별 방문 통계 */}
        <div className="stats-detail-card">
          <h4>
            <FaGlobe />
            {language === 'KR' ? ' 국가별 방문' : ' Visits by Country'}
          </h4>
          <div className="stats-list">
            {countryStats.map((country, index) => (
              <div key={index} className="stats-list-item">
                <div className="country-flag">{getCountryFlag(country.country_code)}</div>
                <div className="country-name">{country.country_code}</div>
                <div className="country-count">{country.count}</div>
                <div className="country-percent">({calculatePercentage(country.count, countryStats)}%)</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// 디바이스 유형별 색상 지정
const getDeviceColor = (deviceType) => {
  const colors = {
    'iOS': '#3498db',
    'Android': '#2ecc71',
    'Windows Desktop': '#9b59b6',
    'Mac Desktop': '#e74c3c',
    'Linux Desktop': '#f39c12',
    'Windows Phone': '#1abc9c',
    'Unknown': '#95a5a6'
  };
  
  return colors[deviceType] || '#95a5a6';
};

// 국가 코드에 해당하는 이모지 국기 반환
const getCountryFlag = (countryCode) => {
  // 국가 코드를 지역 표시자로 변환 (ISO 3166-1 alpha-2)
  const flagOffset = 0x1F1E6;
  const asciiOffset = 0x41;
  
  const firstChar = countryCode.charCodeAt(0) - asciiOffset + flagOffset;
  const secondChar = countryCode.charCodeAt(1) - asciiOffset + flagOffset;
  
  // 국기 이모지 생성
  return String.fromCodePoint(firstChar) + String.fromCodePoint(secondChar);
};

// 백분율 계산 헬퍼 함수
const calculatePercentage = (count, items) => {
  const total = items.reduce((sum, item) => sum + item.count, 0);
  return Math.round((count / total) * 100);
};

export default PageStatsDashboard;