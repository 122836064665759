// Manage.js - 관리자 페이지 컴포넌트
import React, { useState, useEffect } from 'react';
import { FaChartBar, FaTable, FaChartLine } from 'react-icons/fa';
import './Manage.css';

// 모듈화된 컴포넌트 가져오기
import VisitCountTab from './components/manage/VisitCountTab';
import VisitStatsTab from './components/visit/VisitStatsTab'; // 방문자 통계 탭
import DataManageTab from './components/manage/DataManageTab';
import StatusMessage from './components/manage/StatusMessage';
import PageStatsDashboard from './components/visit/PageStatsDashboard'; // 페이지 통계 대시보드

function Manage({ language, user }) {
  const [activeTab, setActiveTab] = useState('visitstats'); // 기본 탭을 방문자 통계로 설정
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [selectedPage, setSelectedPage] = useState(null); // 선택한 페이지 추적

  const userEmail = user?.email || 'unknown';
  const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';

  // 관리자만 접근 가능하도록 확인
  if (userEmail !== 'gategil1149@gmail.com') {
    return (
      <div className="Manage">
        <header className="Manage-header">
          <h1>{language === 'KR' ? '관리 페이지' : 'Management Page'}</h1>
        </header>
        <div className="access-denied">
          <h2>{language === 'KR' ? '접근 권한이 없습니다' : 'Access Denied'}</h2>
          <p>{language === 'KR' ? '이 페이지는 관리자만 접근할 수 있습니다.' : 'This page is only accessible to administrators.'}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="Manage">
      <header className="Manage-header">
        <h1>{language === 'KR' ? '관리 페이지' : 'Management Page'}</h1>
      </header>
      <main>
        <div className="ManageContainer">
          <div className="tab-buttons">
            <button
              onClick={() => {
                setActiveTab('visitstats');
                setSelectedPage(null); // 탭 변경 시 선택한 페이지 초기화
              }}
              className={activeTab === 'visitstats' ? 'active' : 'inactive'}
            >
              <FaChartLine />
              {language === 'KR' ? ' 방문자 통계' : ' Visit Statistics'}
            </button>
            <button
              onClick={() => {
                setActiveTab('visitcount');
                setSelectedPage(null);
              }}
              className={activeTab === 'visitcount' ? 'active' : 'inactive'}
            >
              <FaChartBar />
              {language === 'KR' ? ' 방문자 수 조회' : ' Visit Count'}
            </button>
            <button
              onClick={() => {
                setActiveTab('datamanage');
                setSelectedPage(null);
              }}
              className={activeTab === 'datamanage' ? 'active' : 'inactive'}
            >
              <FaTable />
              {language === 'KR' ? ' 데이터 관리' : ' Data Management'}
            </button>
          </div>

          {statusMessage && (
            <StatusMessage message={statusMessage} isLoading={isLoading} />
          )}

          <div className="manage-content">
            {/* 방문자 통계 탭 */}
            {activeTab === 'visitstats' && !selectedPage && (
              <VisitStatsTab 
                language={language} 
                userEmail={userEmail} 
                SERVER_ROOT={SERVER_ROOT} 
                setIsLoading={setIsLoading}
                setStatusMessage={setStatusMessage}
                onSelectPage={setSelectedPage} // 페이지 선택 핸들러 전달
              />
            )}
            
            {/* 페이지 상세 통계 대시보드 */}
            {activeTab === 'visitstats' && selectedPage && (
              <div className="page-stats-dashboard-container">
                <div className="back-button-container">
                  <button 
                    className="back-button"
                    onClick={() => setSelectedPage(null)}
                  >
                    {language === 'KR' ? '← 전체 통계로 돌아가기' : '← Back to All Statistics'}
                  </button>
                </div>
                <PageStatsDashboard
                  language={language}
                  userEmail={userEmail}
                  SERVER_ROOT={SERVER_ROOT}
                  setIsLoading={setIsLoading}
                  setStatusMessage={setStatusMessage}
                  pageName={selectedPage}
                />
              </div>
            )}
            
            {/* 방문자 수 조회 탭 */}
            {activeTab === 'visitcount' && (
              <VisitCountTab 
                language={language} 
                userEmail={userEmail} 
                SERVER_ROOT={SERVER_ROOT} 
                setIsLoading={setIsLoading}
                setStatusMessage={setStatusMessage}
              />
            )}

            {/* 데이터 관리 탭 */}
            {activeTab === 'datamanage' && (
              <DataManageTab 
                language={language} 
                userEmail={userEmail} 
                SERVER_ROOT={SERVER_ROOT}
                setIsLoading={setIsLoading}
                setStatusMessage={setStatusMessage}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Manage;