import React, { useState, useEffect } from 'react';
import Resizer from 'react-image-file-resizer';
import { Helmet } from 'react-helmet'; // react-helmet import
import './ImageConvert.css';

import { recordPageVisit } from './utils/pageVisitTracker';

const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';

function ImageConvert({ language, user, countryCode, userIp  }) {
  const [imageFile, setImageFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [outputFormat, setOutputFormat] = useState('png'); // Default format
  const [containerStyle, setContainerStyle] = useState({
    width: '350px',
    height: '350px',
  });
  const [downloadLinks, setDownloadLinks] = useState([]); // 추가된 상태
  const [message, setMessage] = useState(""); // State for message display
  const [outputImage, setOutputImage] = useState(null); // State to store output image URL

  const [outputSize, setOutputSize] = useState('original'); // Default size: same as the original
  const [customWidth, setCustomWidth] = useState(''); // Custom width
  const [customHeight, setCustomHeight] = useState(''); // Custom height
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  }); 
  const [outputImageDimensions, setOutputImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  // 이미지 크기 상태 추가
  const [preserveAspectRatio, setPreserveAspectRatio] = useState(true);

  const userId = user ? user.uid : 'unknown'; 

  
  const isMobileDevice = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
  useEffect(() => {
    recordPageVisit('ImageConvert', {
      userId,
      userIp,
      countryCode,
      SERVER_ROOT,
      language,
      isMobileDevice
    });
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {

          let maxWidth = 350; // 최대 가로 크기
          let maxHeight = 350; // 최대 세로 크기

          if (isMobileDevice) {
            maxWidth = 350; // 최대 가로 크기
            maxHeight = 350; // 최대 세로 크기 
          }
          
          // 이미지 크기 추출
          setImageDimensions({
            width: img.width,
            height: img.height,
          });

          let width = img.width;
          let height = img.height;
  
          // 가로 세로 비율 계산
          const aspectRatio = width / height;
  
          if (width > maxWidth || height > maxHeight) {
            if (aspectRatio > 1) {
              // 가로가 세로보다 크거나 같은 경우
              width = maxWidth;
              height = maxWidth / aspectRatio;
            } else {
              // 세로가 가로보다 큰 경우
              height = maxHeight;
              width = maxHeight * aspectRatio;
            }
          }
  
          // 컨테이너 스타일 업데이트
          setContainerStyle({
            width: `${width}px`,
            height: `${height}px`,
          });
        };
        img.src = reader.result; // 이미지 로드
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setImageFile(file);

      setMessage(
        language === "KR"
            ? "출력 포멧을 선택하고, 포멧변환 버튼을 누르세요."
            : "Choose the output format and submit!"
    );
    }
  }; 

  const upscaleImage = (imageFile, outputWidth, outputHeight, callback) => {
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        
        canvas.width = outputWidth;
        canvas.height = outputHeight;
  
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, outputWidth, outputHeight);
  
        canvas.toBlob((blob) => {
          const newFile = new File([blob], imageFile.name, {
            type: imageFile.type,
          });
  
          const dataUrl = canvas.toDataURL(imageFile.type); // Data URL for preview or download
          callback(newFile, dataUrl); // 새로운 File 객체와 Data URL을 콜백으로 전달
        }, imageFile.type);
      };
      img.src = e.target.result;
    };
  
    reader.readAsDataURL(imageFile);
  };

  const handleImageSave = async () => {

    if (!imageFile) {
      alert(language === 'KR' ? '이미지를 업로드하세요.' : 'Please upload an image.');
      return;
    }

    // 업로드된 이미지 크기 확인
    console.log(`here width: ${imageDimensions.width} and height: ${imageDimensions.height}`);


    let originalWidth = imageDimensions.width; // 기본 값 처리
    const originalHeight = imageDimensions.height;
    let outputWidth = outputSize === 'newsize' ? parseInt(customWidth) : originalWidth;
    let outputHeight = outputSize === 'newsize' ? parseInt(customHeight) : originalHeight;

    if (preserveAspectRatio) {
        // 가로 세로 비율 계산
        const aspectRatio = originalWidth / originalHeight;
   
        if (aspectRatio > 1) {
        // 가로가 세로보다 크거나 같은 경우 
        outputHeight = outputWidth / aspectRatio;
        } else {
        // 세로가 가로보다 큰 경우 
        outputWidth = outputHeight * aspectRatio;
        } 
    }

    if (outputSize === 'newsize' && (!outputWidth || !outputHeight)) {
        alert(language === 'KR' ? '유효한 크기를 입력하세요.' : 'Please enter valid dimensions.');
        return;
    }

    // Implement resizing logic here with `width` and `height`
    console.log(`original image has width: ${originalWidth} and height ${originalHeight}`);
    console.log(`Saving image with width: ${outputWidth}, height: ${outputHeight}, format: ${outputFormat}`);


    try {

      // Resize the image
      if (outputWidth > originalWidth || outputHeight > originalHeight) {
        // 업스케일링 수행
        upscaleImage(imageFile, outputWidth, outputHeight, (newFile, dataUrl) => {
          

          // 원본 이미지의 확장자를 유지
          const originalFormat = imageFile.name.split(".").pop();
          
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = `${imageFile.name.split(".")[0]}_upscaled.${originalFormat}`;
          link.click();

          setMessage(
            language === "KR"
              ? `이미지가 ${outputWidth}x${outputHeight}로 업스케일링되었습니다.`
              : `Image has been upscaled to ${outputWidth}x${outputHeight}.`
          );

        //   setImageFile(newFile); // 업스케일링 결과를 imageFile에 저장

          Resizer.imageFileResizer(
            newFile, // 업스케일링된 파일 사용
            // imageFile.width, // maxWidth
            // imageFile.height, // maxHeight
            outputWidth,
            outputHeight,
            outputFormat.toUpperCase(), // Use selected format
            100, // Quality
            0, // Rotation
            async (uri) => {
              setOutputImage(uri); // Set the output image to display below
    
              if (isMobileDevice()) {
                const link = document.createElement('a');
                link.href = uri;
                link.download = `${newFile.name.split('.')[0]}_converted.${outputFormat}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                setDownloadLinks((prevLinks) => [
                  ...prevLinks,
                  {
                    href: uri,
                    filename: `${newFile.name.split('.')[0]}_converted.${outputFormat}`,
                  },
                ]);
    
                setMessage(
                    language === "KR"
                        ? "이미지가 스마트폰의 파일 폴더에 저장되었습니다."
                        : "Image has been saved successfully!"
                );
                alert(
                  language === 'KR'
                    ? "이미지가 스마트폰의 파일 폴더에 저장되었습니다."
                        : "Image has been saved successfully!"
                );
              } else {
                const options = {
                    suggestedName: `${newFile.name.split('.')[0]}_converted.${outputFormat}`,
                    types: [
                    {
                        description: "Images",
                        accept: {
                        "image/png": [".png"],
                        "image/jpeg": [".jpg", ".jpeg"],
                        "image/gif": [".gif"],
                        "image/webp": [".webp"],
                        "image/svg": [".svg"],
                        "image/tiff": [".tiff"],
                        },
                    },
                    ],
                };
    
                let fileHandle = null;
                console.log('here1');
                try {
                    fileHandle = await window.showSaveFilePicker(options);
                } catch (error) { 
                    alert(language === 'KR' ? '저장할 폴더를 선택하세요.' : 'Please select the destination.');
                    return;
                }  
    
                const writableStream = await fileHandle.createWritable();
    
                console.log('here4');
                const binaryData = atob(uri.split(",")[1]); // Decode Base64
                const buffer = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    buffer[i] = binaryData.charCodeAt(i);
                }
    
                await writableStream.write(buffer);
                await writableStream.close();
    
                setMessage(
                    language === "KR"
                        ? "이미지가 선택하신 폴더에 저장되었습니다."
                        : "Image has been saved successfully!"
                );
    
                alert(
                    language === 'KR'
                    ? "이미지가 선택하신 폴더에 저장되었습니다."
                        : "Image has been saved successfully!"
                );
              }
            },
            "base64"
          );
        }); 
      }
      else {
        Resizer.imageFileResizer(
            imageFile, // 원본 파일 사용
            // imageFile.width, // maxWidth
            // imageFile.height, // maxHeight
            outputWidth,
            outputHeight,
            outputFormat.toUpperCase(), // Use selected format
            100, // Quality
            0, // Rotation
            async (uri) => {
              setOutputImage(uri); // Set the output image to display below
    
              if (isMobileDevice()) {
                const link = document.createElement('a');
                link.href = uri;
                link.download = `${imageFile.name.split('.')[0]}_converted.${outputFormat}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                setDownloadLinks((prevLinks) => [
                  ...prevLinks,
                  {
                    href: uri,
                    filename: `${imageFile.name.split('.')[0]}_converted.${outputFormat}`,
                  },
                ]);
    
                setMessage(
                    language === "KR"
                        ? "이미지가 스마트폰의 파일 폴더에 저장되었습니다."
                        : "Image has been saved successfully!"
                );
                alert(
                  language === 'KR'
                    ? "이미지가 스마트폰의 파일 폴더에 저장되었습니다."
                        : "Image has been saved successfully!"
                );
              } else {
                const options = {
                    suggestedName: `${imageFile.name.split('.')[0]}_converted.${outputFormat}`,
                    types: [
                    {
                        description: "Images",
                        accept: {
                        "image/png": [".png"],
                        "image/jpeg": [".jpg", ".jpeg"],
                        "image/gif": [".gif"],
                        "image/webp": [".webp"],
                        "image/svg": [".svg"],
                        "image/tiff": [".tiff"],
                        },
                    },
                    ],
                };
    
                let fileHandle = null; 
                try {
                    fileHandle = await window.showSaveFilePicker(options);
                } catch (error) { 
                    alert(language === 'KR' ? '저장할 폴더를 선택하세요.' : 'Please select the destination.');
                    return;
                }  
    
                const writableStream = await fileHandle.createWritable();
     
                const binaryData = atob(uri.split(",")[1]); // Decode Base64
                const buffer = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    buffer[i] = binaryData.charCodeAt(i);
                }
    
                await writableStream.write(buffer);
                await writableStream.close();
    
                setMessage(
                    language === "KR"
                        ? "이미지가 선택하신 폴더에 저장되었습니다."
                        : "Image has been saved successfully!"
                );
    
                alert(
                    language === 'KR'
                    ? "이미지가 선택하신 폴더에 저장되었습니다."
                        : "Image has been saved successfully!"
                );
              }
            },
            "base64"
          );
      }
      
    } catch (error) {
      console.error("Error saving image:", error);
      alert(
        language === 'KR'
          ? '이미지 저장 중 오류가 발생했습니다.'
          : 'An error occurred while saving the image.'
      );
    }
  };

  useEffect(() => {
    console.log("Message:", message);
    console.log("Output Image:", outputImage);
  }, [message, outputImage]);
  
  const handleAspectRatioToggle = () => {
    setPreserveAspectRatio(!preserveAspectRatio);  
  };

  return (
    <div className="image-convert">
      <Helmet>
        <title>Image Format Converter - Convert and Save Images | Doldari</title>
        <link rel="icon" href="/images/thumbnail_imageconverter.png" /> 
        <meta
          name="description"
          content="Convert any Image Format Online for Free (PNG, JPEG, GIF, WebP, SVG, TIFF) with our Image Format Converter. Upload, preview, and save images in your desired format."
        />
        <meta
          name="keywords"
          content="image converter, image resizer, image, converter, size, format, free"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Image Format Converter" />
        <meta
          property="og:description"
          content="Easily convert image formats with Doldari's Image Format Converter."
        />
        <meta property="og:url" content="https://www.doldari.com/ImageConvert" />
        <meta property="og:type" content="website" />  
        <meta property="og:image" content="https://www.doldari.com/resources/logo-imageconverter.png" /> 
      </Helmet>
      <div className="image-convert-header">
        {/* <h1>{language === 'KR' ? '이미지 포멧 변환기' : 'Image Format Converter'}</h1> */}
        <p className="instruction-text">
          {language === 'KR'
            ? '이미지 파일의 포멧을 자유롭게 변환하세요. 해상도를 유지하면서 축소/확대를 할 수 있습니다. '
            : 'Convert the format of an image with ease. Adjust the image size without loosing the resolution.'}
        </p>
      </div>

      
      <div className='imageconvert-main'>
        

        <div className='image-control'>

            <div className="controls">
                <label htmlFor="outputFormat" className="format-label">
                {language === 'KR' ? '입력 파일:' : 'Input file:'}
                </label>
                <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="upload-button"
                />
                
            </div>
            <div className="output-file">
                <label htmlFor="outputFormat" className="format-label">
                {language === 'KR' ? '출력 포맷:' : 'Output Format:'}
                </label>
                <select
                id="outputFormat"
                value={outputFormat}
                onChange={(e) => setOutputFormat(e.target.value)}
                className="format-dropdown"
                >
                <option value="png">PNG</option>
                <option value="jpeg">JPEG</option>
                <option value="gif">GIF</option>
                <option value="webp">WebP</option>
                <option value="svg">SVG</option>
                <option value="tiff">TIFF</option>
                <option value="ICO">ICO</option>
                </select>  

                <label htmlFor="outputSize">{language === 'KR' ? '출력 크기:' : 'Output Size:'}</label>
                <select
                    id="outputSize"
                    value={outputSize}
                    onChange={(e) => setOutputSize(e.target.value)}
                    className="size-dropdown"
                >
                    <option value="original">{language === 'KR' ? '원본 크기' : 'Same as the original image'}</option>
                    <option value="newsize">{language === 'KR' ? '사용자 지정 크기' : 'Custom Size'}</option>
                </select>

                {outputSize === 'newsize' && (
                    <div className='inputNewSize'>
                        <div>
                        <label htmlFor="customWidth">{language === 'KR' ? '너비:' : 'Width:'}</label>
                        <input
                            type="number"
                            id="customWidth"
                            value={customWidth}
                            onChange={(e) => setCustomWidth(e.target.value)}
                            placeholder={language === 'KR' ? '픽셀 단위' : 'In pixels'}
                            style={{ marginRight: '10px' }}
                        />
                        </div>
                        
                        <div>
                        <label htmlFor="customHeight">{language === 'KR' ? '높이:' : 'Height:'}</label>
                        <input
                            type="number"
                            id="customHeight"
                            value={customHeight}
                            onChange={(e) => setCustomHeight(e.target.value)}
                            placeholder={language === 'KR' ? '픽셀 단위' : 'In pixels'}
                        />
                        </div>
                        <div style={{ display: "inline-block", marginLeft: "10px" }}>
                            <input
                                type="checkbox"
                                id="preserveAspectRatio"
                                checked={preserveAspectRatio}
                                onChange={handleAspectRatioToggle}
                            />
                            <label htmlFor="preserveAspectRatio" style={{ marginLeft: "5px" }}>
                                {language === "KR" ? "비율 유지" : "Preserve Aspect Ratio"}
                            </label>
                        </div>
                    </div>
                    
                )}

                <button
                    onClick={(event) => {
                        event.preventDefault(); // 기본 동작 방지
                        handleImageSave();
                    }}
                    className="save-button"
                >
                    {language === 'KR' ? '포멧 변환' : 'Submit'}
                </button>
            </div>
        </div> 

        <div className="image-preview" style={containerStyle}>
            {previewUrl ? (
            <img src={previewUrl} alt="Preview" className="preview-image" />
            ) : (
            <div className="placeholder">
                {language === 'KR' ? '이미지를 업로드하세요.' : 'Upload an image.'}
            </div>
            )}
        </div>

      </div>

      <div className="output-container"> 
        {message && <div className="message">{message}</div>}

        {/* Output Image Display */}
        {outputImage && (
            <div className="output-image-container">
            <h3>{language === 'KR' ? '변환된 이미지:' : 'Converted Image:'}</h3>
            <img src={outputImage} alt="Converted Output" className="output-image" />
            </div>
        )}
      </div>

      {/* 다운로드 링크 리스트 */}
      {/* {downloadLinks.length > 0 && (
        <div className="download-links">
          <h3>{language === 'KR' ? '다운로드 링크:' : 'Download Links:'}</h3>
          <ul>
            {downloadLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href} download={link.filename}>
                  {link.filename}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )} */}
    </div>
  );
}

export default ImageConvert;
