// components/manage/TableSelector.js
import React from 'react';

function TableSelector({ selectedTable, onSelectTable, language }) {
  return (
    <div className="table-selection-container">
      <select
        value={selectedTable}
        onChange={(e) => onSelectTable(e.target.value)}
        className="table-select"
      >
        <option value="">-- {language === 'KR' ? '테이블 선택' : 'Select Table'} --</option>
        <option value="HSwords_en">수능 영단어</option>
        <option value="Toeicwords_en">토익 영단어</option>
        <option value="SATwords_en">SAT 영단어</option>
        <option value="GREwords_en">GRE 영단어</option>
        <option value="words_kr">국내 배우 이름 (KR)</option>
        <option value="words_en">외국 배우 이름 (EN)</option>
        <option value="users">USERS table</option>
        <option value="sitelist">SITELIST table</option>
        <option value="page_visits"> page_visits table </option>
      </select>
    </div>
  );
}

export default TableSelector;