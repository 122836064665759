// components/manage/RecordActions.js
import React from 'react';
import { FaPlus, FaEdit, FaTrash, FaSearch } from 'react-icons/fa';

function RecordActions({ 
  onAdd, 
  onEdit, 
  onDelete, 
  searchTerm, 
  onSearchChange, 
  selectedRecord, 
  isLoading, 
  language 
}) {
  return (
    <div className="record-control">
      <button 
        className="action-button add" 
        onClick={onAdd}
        disabled={isLoading}
      >
        <FaPlus /> {language === 'KR' ? '추가' : 'Add'}
      </button>
      
      <button 
        className="action-button edit" 
        onClick={onEdit}
        disabled={isLoading || !selectedRecord}
      >
        <FaEdit /> {language === 'KR' ? '수정' : 'Edit'}
      </button>

      <button 
        className="action-button delete" 
        onClick={onDelete}
        disabled={isLoading || !selectedRecord}
      >
        <FaTrash /> {language === 'KR' ? '삭제' : 'Delete'}
      </button>
      
      <div className="search-container">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder={language === 'KR' ? '검색...' : 'Search...'}
          value={searchTerm}
          onChange={onSearchChange}
          className="search-input"
        />
      </div>
    </div>
  );
}

export default RecordActions;