// components/manage/StatusMessage.js
import React from 'react';

function StatusMessage({ message, isLoading }) {
  return (
    <div className={`status-message ${isLoading ? 'loading' : ''}`}>
      {message}
      {isLoading && <div className="loading-spinner"></div>}
    </div>
  );
}

export default StatusMessage;