// components/visit/VisitStatsExport.js
import React, { useState } from 'react';
import { FaFileExport, FaFileCsv, FaFileExcel, FaSpinner } from 'react-icons/fa';
import axios from 'axios';
import './VisitStatsExport.css';

/**
 * 방문자 통계 데이터를 다양한 형식으로 내보내는 컴포넌트
 * @param {Object} props
 * @param {string} props.language - 언어 설정 ('KR' 또는 'EN')
 * @param {string} props.SERVER_ROOT - 서버 URL
 * @param {string} props.pageName - 페이지 이름 (특정 페이지 필터링용, 선택 사항)
 * @param {string} props.period - 조회 기간 ('daily', 'monthly', 'summary')
 * @param {Function} props.onSuccess - 내보내기 성공 콜백
 * @param {Function} props.onError - 내보내기 실패 콜백
 */
const VisitStatsExport = ({ 
  language, 
  SERVER_ROOT, 
  pageName = null, 
  period = 'summary',
  onSuccess,
  onError
}) => {
  const [exporting, setExporting] = useState(false);
  const [exportFormat, setExportFormat] = useState('csv');
  
  // 파일명 생성 함수
  const generateFileName = () => {
    const date = new Date().toISOString().slice(0, 10);
    const pageStr = pageName ? `_${pageName}` : '';
    return `visit_stats_${period}${pageStr}_${date}.${exportFormat}`;
  };
  
  // 방문 통계 데이터 추출 및 변환
  const getExportData = async () => {
    try {
      // 통계 데이터 가져오기
      const response = await axios.get(`${SERVER_ROOT}/api/visit-stats`, {
        params: { 
          userEmail: 'gategil1149@gmail.com',
          period,
          page_name: pageName
        }
      });
      
      if (!response.data || response.data.length === 0) {
        throw new Error(language === 'KR' 
          ? '내보낼 데이터가 없습니다' 
          : 'No data to export');
      }
      
      // 기간에 따라 열 헤더 설정
      let headers;
      switch (period) {
        case 'daily':
          headers = language === 'KR' 
            ? ['날짜', '페이지', '총 방문수', '고유 방문자', '고유 세션'] 
            : ['Date', 'Page', 'Total Visits', 'Unique Visitors', 'Unique Sessions'];
          break;
        case 'monthly':
          headers = language === 'KR' 
            ? ['월', '페이지', '총 방문수', '고유 방문자', '고유 세션'] 
            : ['Month', 'Page', 'Total Visits', 'Unique Visitors', 'Unique Sessions'];
          break;
        case 'summary':
        default:
          headers = language === 'KR' 
            ? ['페이지', '총 방문수', '고유 방문자', '고유 세션', '방문 일수', '첫 방문', '최근 방문'] 
            : ['Page', 'Total Visits', 'Unique Visitors', 'Unique Sessions', 'Visit Days', 'First Visit', 'Last Visit'];
          break;
      }
      
      // 데이터 행 매핑
      let rows;
      switch (period) {
        case 'daily':
          rows = response.data.map(item => [
            item.visit_date, 
            item.page_name, 
            item.total_visits, 
            item.unique_visitors, 
            item.unique_sessions
          ]);
          break;
        case 'monthly':
          rows = response.data.map(item => [
            item.month, 
            item.page_name, 
            item.total_visits, 
            item.unique_visitors, 
            item.unique_sessions
          ]);
          break;
        case 'summary':
        default:
          rows = response.data.map(item => [
            item.page_name, 
            item.total_visits, 
            item.unique_visitors, 
            item.unique_sessions,
            item.visit_days,
            item.first_visit,
            item.last_visit
          ]);
          break;
      }
      
      return { headers, rows };
    } catch (error) {
      console.error('Error preparing export data:', error);
      throw error;
    }
  };
  
  // CSV 포맷으로 변환
  const convertToCSV = (headers, rows) => {
    // 헤더 행
    let csv = headers.join(',') + '\n';
    
    // 데이터 행
    rows.forEach(row => {
      // 각 값을 적절히 처리 (쉼표 포함된 문자열에 따옴표 추가)
      const formattedRow = row.map(value => {
        const str = value === null || value === undefined ? '' : String(value);
        return str.includes(',') ? `"${str}"` : str;
      });
      
      csv += formattedRow.join(',') + '\n';
    });
    
    return csv;
  };
  
  // Excel XML 포맷으로 변환 (간단한 Excel 호환 XML)
  const convertToExcel = (headers, rows) => {
    let xml = '<?xml version="1.0"?>\n';
    xml += '<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n';
    xml += '<Worksheet ss:Name="Visit Statistics">\n';
    xml += '<Table>\n';
    
    // 헤더 행
    xml += '<Row>\n';
    headers.forEach(header => {
      xml += `<Cell><Data ss:Type="String">${header}</Data></Cell>\n`;
    });
    xml += '</Row>\n';
    
    // 데이터 행
    rows.forEach(row => {
      xml += '<Row>\n';
      row.forEach(value => {
        // 데이터 타입 결정 (숫자 또는 문자열)
        const isNumber = !isNaN(value) && value !== null && value !== '';
        xml += `<Cell><Data ss:Type="${isNumber ? 'Number' : 'String'}">${value}</Data></Cell>\n`;
      });
      xml += '</Row>\n';
    });
    
    xml += '</Table>\n';
    xml += '</Worksheet>\n';
    xml += '</Workbook>';
    
    return xml;
  };
  
  // 데이터 내보내기 처리
  const handleExport = async () => {
    setExporting(true);
    
    try {
      // 데이터 가져오기
      const { headers, rows } = await getExportData();
      
      // 선택된 형식으로 변환
      let content;
      let mimeType;
      
      if (exportFormat === 'csv') {
        content = convertToCSV(headers, rows);
        mimeType = 'text/csv';
      } else if (exportFormat === 'xlsx' || exportFormat === 'xls') {
        content = convertToExcel(headers, rows);
        mimeType = 'application/vnd.ms-excel';
      } else {
        throw new Error('Unsupported export format');
      }
      
      // 파일 다운로드 트리거
      const blob = new Blob([content], { type: mimeType });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = generateFileName();
      link.click();
      
      // 메모리 정리
      setTimeout(() => URL.revokeObjectURL(url), 100);
      
      // 성공 콜백 호출
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error('Error exporting data:', error);
      
      // 오류 콜백 호출
      if (onError) {
        onError(error.message);
      }
    } finally {
      setExporting(false);
    }
  };
  
  return (
    <div className="visit-stats-export">
      <div className="export-controls">
        <select 
          value={exportFormat} 
          onChange={(e) => setExportFormat(e.target.value)}
          className="export-format-select"
          disabled={exporting}
        >
          <option value="csv">CSV</option>
          <option value="xlsx">Excel</option>
        </select>
        
        <button 
          onClick={handleExport} 
          disabled={exporting}
          className="export-button"
        >
          {exporting ? (
            <>
              <FaSpinner className="spinner" />
              {language === 'KR' ? '내보내는 중...' : 'Exporting...'}
            </>
          ) : (
            <>
              {exportFormat === 'csv' ? <FaFileCsv /> : <FaFileExcel />}
              {language === 'KR' ? ' 내보내기' : ' Export'}
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default VisitStatsExport;