// components/manage/RecordList.js
import React from 'react';
import { FaSortUp, FaSortDown } from 'react-icons/fa';

function RecordList({ 
  fields, 
  records, 
  selectedRecord, 
  onSelectRecord, 
  onSort,
  sortField, 
  sortDirection,
  selectedTimezone,
  language 
}) {
  // 날짜와 시간을 지정된 시간대로 변환하는 함수
  const formatDateTime = (value, fieldType, fieldName) => {
    if (!value) return value;
    
    // 필드명에 따른 특별 처리
    if (fieldName === 'visit_date') {
      try {
        // Date 객체 생성
        const date = new Date(value);
        if (isNaN(date.getTime())) return value; // 유효하지 않은 날짜인 경우 원본 반환
        
        // 날짜만 포맷팅 (시간 제외)
        const options = {
          timeZone: selectedTimezone,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        };
        
        return new Intl.DateTimeFormat('ko-KR', options).format(date);
      } catch (error) {
        console.error('Error formatting date:', error);
        return value;
      }
    }
    
    if (fieldName === 'visit_time') {
      try {
        // Date 객체 생성
        const date = new Date(value);
        if (isNaN(date.getTime())) return value; // 유효하지 않은 날짜인 경우 원본 반환
        
        // 날짜와 시간 모두 포맷팅 (전체 날짜 시간)
        const options = {
          timeZone: selectedTimezone,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        };
        
        return new Intl.DateTimeFormat('ko-KR', options).format(date);
      } catch (error) {
        console.error('Error formatting time:', error);
        return value;
      }
    }
    
    // 일반적인 날짜 타입 필드인지 확인
    const isDateType = fieldType && (
      fieldType.includes('date') || 
      fieldType.includes('time') || 
      fieldType.includes('timestamp')
    );
    
    if (!isDateType) return value;
    
    try {
      // Date 객체 생성
      const date = new Date(value);
      if (isNaN(date.getTime())) return value; // 유효하지 않은 날짜인 경우 원본 반환
      
      // 시간대를 적용하여 날짜/시간 포맷팅
      const options = {
        timeZone: selectedTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      };
      
      return new Intl.DateTimeFormat('ko-KR', options).format(date);
    } catch (error) {
      console.error('Error formatting date:', error);
      return value; // 오류 발생 시 원본 값 반환
    }
  };

  return (
    <div className="record-list-container">
      <h3>{language === 'KR' ? '레코드 목록' : 'Record List'}</h3>
      {records.length > 0 ? (
        <div className="table-container">
          <table className="record-table">
            <thead>
              <tr>
                {fields.map((field) => (
                  <th 
                    key={field.Field} 
                    onClick={() => onSort(field.Field)}
                    className={sortField === field.Field ? 'sorted' : ''}
                  >
                    {field.Field}
                    {sortField === field.Field && (
                      <span className="sort-icon">
                        {sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr
                  key={record.id}
                  className={selectedRecord?.id === record.id ? 'selected' : ''}
                  onClick={() => onSelectRecord(record)}
                >
                  {fields.map((field) => (
                    <td key={field.Field}>
                      {field.Type.includes('text') && record[field.Field] && record[field.Field].length > 100
                        ? record[field.Field].substring(0, 100) + '...'
                        : formatDateTime(record[field.Field], field.Type, field.Field)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-data-message">
          {language === 'KR' ? '데이터가 없습니다.' : 'No data available.'}
        </div>
      )}
    </div>
  );
}

export default RecordList;