// utils/SimpleVisitCounter.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEye } from 'react-icons/fa';
import './SimpleVisitCounter.css';

/**
 * 간단한 방문자 카운터 컴포넌트 - 어느 페이지에나 쉽게 추가 가능
 * @param {string} pageName - 방문자 수를 표시할 페이지 이름 (기본값: 현재 페이지)
 * @param {string} language - 언어 설정
 * @param {string} SERVER_ROOT - 서버 URL
 */
const SimpleVisitCounter = ({ pageName = null, language, SERVER_ROOT }) => {
  const [visitStats, setVisitStats] = useState({
    total: 0,
    today: 0,
    loading: true
  });

  // 현재 페이지 이름이 제공되지 않은 경우 경로에서 추출 
  useEffect(() => {
    const fetchVisitStats = async () => {
      try {
        // 현재 페이지 이름 결정 (기존 코드와 동일)
        let currentPage = pageName;
        if (!currentPage) {
          const path = window.location.pathname;
          if (path === '/') {
            currentPage = 'Home';
          } else {
            const pathWithoutSlash = path.substring(1);
            currentPage = pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1);
          }
        }

        console.log(`currentPage: ${currentPage}`);

        // 총 방문자 수 가져오기
        const summaryResponse = await axios.get(`${SERVER_ROOT}/api/visit-stats`, {
          params: { 
            userEmail: 'gategil1149@gmail.com',
            period: 'summary',
            page_name: currentPage
          }
        });

        console.log(`summaryResponse: ${summaryResponse}`);

        // 한국 시간으로 오늘 날짜 구하기
        const now = new Date();
        const koreaTime = new Date(now.getTime() + (9 * 60 * 60 * 1000));
        const todayDate = koreaTime.toISOString().split('T')[0]; // YYYY-MM-DD

        console.log('Checking for today\'s visits with date:', todayDate);
        
        // 일별 방문자 데이터 가져오기
        const dailyResponse = await axios.get(`${SERVER_ROOT}/api/visit-stats`, {
          params: { 
            userEmail: 'gategil1149@gmail.com', 
            period: 'daily',
            page_name: currentPage
          }
        });
        
        // 오늘 데이터 찾기 (API 응답의 구조를 직접 출력하고 확인)
        console.log('Daily response data:', dailyResponse.data);
        
        // 오늘 날짜와 정확히 일치하는 항목 찾기
        const todayStats = dailyResponse.data.find(entry => { 
          const entryDateKST = new Date(entry.visit_date);
          entryDateKST.setHours(entryDateKST.getHours() + 9); // UTC to KST
          const entryDate = entryDateKST.toISOString().substring(0, 10);
          const matches = entryDate === todayDate;
          console.log(`Comparing ${entryDate} with ${todayDate}: ${matches}`);
          return matches;
        });
        
        console.log('Found today stats:', todayStats);
        
        // 통계 업데이트
        setVisitStats({
          total: summaryResponse.data[0]?.total_visits || 0,
          today: todayStats?.total_visits || 0,
          loading: false
        });
      } catch (error) {
        console.error('Error fetching visit statistics:', error);
        setVisitStats({
          total: 0,
          today: 0,
          loading: false
        });
      }
    };

    fetchVisitStats();
  }, [pageName, SERVER_ROOT]);

  if (visitStats.loading) {
    return <div className="simple-visit-counter-loading"></div>;
  }

  return (
    <div className="simple-visit-counter">
      <div className="visit-item">
        <FaEye className="visit-icon" />
        <span className="visit-label">
          {language === 'KR' ? '총 방문' : 'Total'}:
        </span>
        <span className="visit-value">{visitStats.total.toLocaleString()}</span>
      </div>
      <div className="visit-item">
        <FaEye className="visit-icon" />
        <span className="visit-label">
          {language === 'KR' ? '오늘' : 'Today'}:
        </span>
        <span className="visit-value">{visitStats.today.toLocaleString()}</span>
      </div>
    </div>
  );
};

export default SimpleVisitCounter;