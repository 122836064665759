import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import axios from 'axios';
import './MusicPlay.css';

// 재생 모드 상수 정의
const PLAY_MODES = {
  SEQUENTIAL: 'sequential',
  RANDOM: 'random',
  REPEAT_ONE: 'repeat_one'
};

const MusicPlay = () => {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [playMode, setPlayMode] = useState(PLAY_MODES.SEQUENTIAL); // 재생 모드를 상태로 관리
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // 디버깅을 위한 로그 상태
  const [debugLog, setDebugLog] = useState([]);

  // 트랙 목록을 상태로 관리
  const [tracks, setTracks] = useState([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

  // 디버깅 로그 함수
  const addDebugLog = (message) => {
    console.log(message);
    setDebugLog(prev => [...prev, { time: new Date().toISOString(), message }]);
  };

  // 서버에서 음악 트랙 목록 가져오기
  useEffect(() => {
    setIsLoading(true);
    const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';
    
    axios.get(`${SERVER_ROOT}/api/music-tracks`)
      .then(response => {
        if (response.data && response.data.length > 0) {
          addDebugLog(`받은 트랙 목록: ${JSON.stringify(response.data)}`);
          
          // 트랙 목록을 처리하고 중복 확장자 문제 방지를 위한 추가 작업
          const processedTracks = response.data.map(track => {
            // 우선 공백 제거
            let cleanTrack = track.trim();
            
            // 이미 알려진 문제 파일이면 이름 수정 (선택 사항)
            if (cleanTrack === 'Coffee in a Cafe - long.MP3') {
              return 'Coffee in a Cafe - long';
            }
            
            return cleanTrack;
          });
          
          addDebugLog(`처리된 트랙 목록: ${JSON.stringify(processedTracks)}`);
          setTracks(processedTracks);
          setIsLoading(false);
        } else {
          const defaultTracks = ['Worldland', 'Nocturnal Whispers', 'Olas en el Mar']; // 기본 트랙
          addDebugLog(`기본 트랙 사용: ${defaultTracks.join(', ')}`);
          setTracks(defaultTracks);
          setIsLoading(false);
        }
      })
      .catch(err => {
        addDebugLog(`트랙 목록 가져오기 오류: ${err.message}`);
        setError('음악 트랙을 로드할 수 없습니다');
        // 에러 발생 시 기본 트랙 사용
        const defaultTracks = ['Worldland', 'Nocturnal Whispers', 'Olas en el Mar'];
        addDebugLog(`기본 트랙 사용: ${defaultTracks.join(', ')}`);
        setTracks(defaultTracks);
        setIsLoading(false);
      });
  }, []);

  // 트랙 인덱스 변경 추적 디버깅
  useEffect(() => {
    if (tracks.length > 0) {
      addDebugLog(`현재 트랙 인덱스: ${currentTrackIndex}, 트랙명: ${tracks[currentTrackIndex]}`);
    }
  }, [currentTrackIndex, tracks]);

  // 이전 트랙이 끝났을 때 다음 트랙 재생을 처리하는 함수
  const handleTrackFinish = React.useCallback(() => {
    addDebugLog('트랙 종료: handleTrackFinish 호출됨');
    addDebugLog(`현재 재생 모드: ${playMode}`);
    
    if (playMode === PLAY_MODES.REPEAT_ONE) {
      // 한곡 반복 모드: 같은 트랙 다시 재생
      addDebugLog('한곡 반복 모드로 현재 곡 다시 재생');
      if (wavesurferRef.current) {
        wavesurferRef.current.play(0);
        setIsPlaying(true);
      }
    } else if (playMode === PLAY_MODES.RANDOM) {
      // 랜덤 모드: 다음 랜덤 트랙 재생
      addDebugLog('랜덤 모드로 다음 랜덤 트랙 선택');
      
      // 이 시점에서 getRandomTrackIndex 함수 직접 호출 대신 인라인으로 구현
      let randomIndex;
      if (tracks.length <= 1) {
        randomIndex = 0;
      } else {
        do {
          randomIndex = Math.floor(Math.random() * tracks.length);
        } while (randomIndex === currentTrackIndex && tracks.length > 1);
      }
      
      addDebugLog(`랜덤 선택된 트랙: index=${randomIndex}, name=${tracks[randomIndex]}`);
      setCurrentTrackIndex(randomIndex);
    } else {
      // 순차 모드: 다음 트랙 재생
      addDebugLog('순차 모드로 다음 트랙 선택');
      const nextIndex = (currentTrackIndex + 1) % tracks.length;
      addDebugLog(`다음 트랙 계산: 현재=${currentTrackIndex}, 다음=${nextIndex}, 전체 트랙 수=${tracks.length}`);
      setCurrentTrackIndex(nextIndex);
    }
  }, [playMode, currentTrackIndex, tracks]);

  // 랜덤 트랙 인덱스 계산 (현재 트랙 제외)
  const getRandomTrackIndex = React.useCallback(() => {
    if (tracks.length <= 1) return 0;
    
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * tracks.length);
    } while (randomIndex === currentTrackIndex && tracks.length > 1);
    
    addDebugLog(`랜덤 트랙 선택: 인덱스=${randomIndex}, 트랙명=${tracks[randomIndex]}`);
    return randomIndex;
  }, [tracks, currentTrackIndex]);

  // 트랙 변경 시 새 트랙 로드
  useEffect(() => {
    if (!tracks.length || isLoading) return;
    
    addDebugLog(`트랙 변경 감지: 인덱스=${currentTrackIndex}, 트랙=${tracks[currentTrackIndex]}`);
    
    if (wavesurferRef.current) {
      addDebugLog('기존 WaveSurfer 인스턴스 소멸');
      wavesurferRef.current.destroy();
      wavesurferRef.current = null;
    }
    
    setTimeout(() => {
      initWaveSurfer();
    }, 100);
  }, [currentTrackIndex, tracks]);

  // WaveSurfer 초기화 함수
  const initWaveSurfer = () => {
    if (!waveformRef.current || !tracks.length) {
      addDebugLog('WaveSurfer 초기화 불가: waveformRef 또는 트랙 목록 없음');
      return;
    }
    
    addDebugLog('WaveSurfer 초기화 시작');
    
    try {
      const wavesurfer = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#ccc',
        progressColor: 'white',
        responsive: true,
        height: 30,
        barWidth: 1,
        splitChannels: false, // 채널 분할 비활성화
        normalize: false,      // 파형 정규화 (선택사항)
      });
      
      wavesurfer.on('ready', () => {
        addDebugLog('WaveSurfer 준비됨');
        setIsReady(true);
        
        // 이전에 재생 중이었으면 자동 재생
        if (isPlaying) {
          wavesurfer.play();
        }
      });
      
      wavesurfer.on('finish', () => {
        addDebugLog('WaveSurfer 트랙 종료 이벤트');
        handleTrackFinish();
      });
      
      wavesurfer.on('error', (error) => {
        addDebugLog(`WaveSurfer 오류: ${error}`);
      });
      
      // 트랙 로드 - 파일명 처리 로직 추가
      let trackName = tracks[currentTrackIndex];
      // 파일명에 이미 확장자가 있는지 확인
      const hasExtension = /\.(mp3|MP3|wav|WAV|ogg|OGG)$/.test(trackName);
      
      // 확장자가 없는 경우에만 .mp3 추가
      const trackPath = hasExtension 
        ? `/ai_music/${trackName}` 
        : `/ai_music/${trackName}.mp3`;
      
      addDebugLog(`트랙 로드: ${trackPath}`);

      // trackPath = encodeURI(trackPath);
      wavesurfer.load(trackPath);
      
      wavesurferRef.current = wavesurfer;
    } catch (error) {
      addDebugLog(`WaveSurfer 초기화 오류: ${error.message}`);
    }
  };

  const togglePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
      setIsPlaying(!isPlaying);
      addDebugLog(`재생/일시정지 토글: ${!isPlaying ? '재생' : '정지'}`);
    }
  };

  const handlePrev = () => {
    if (tracks.length === 0) return;
    
    const prevIndex = (currentTrackIndex - 1 + tracks.length) % tracks.length;
    addDebugLog(`이전 트랙 선택: 현재=${currentTrackIndex}, 이전=${prevIndex}`);
    setCurrentTrackIndex(prevIndex);
  };

  const handleNext = () => {
    if (tracks.length === 0) return;
    
    const nextIndex = (currentTrackIndex + 1) % tracks.length;
    addDebugLog(`다음 트랙 선택: 현재=${currentTrackIndex}, 다음=${nextIndex}`);
    setCurrentTrackIndex(nextIndex);
  };

  // 재생 모드 전환 함수 - 순차 -> 랜덤 -> 한곡 반복 -> 순차
  const togglePlayMode = () => {
    setPlayMode(prevMode => {
      let newMode;
      if (prevMode === PLAY_MODES.SEQUENTIAL) {
        newMode = PLAY_MODES.RANDOM;
      } else if (prevMode === PLAY_MODES.RANDOM) {
        newMode = PLAY_MODES.REPEAT_ONE;
      } else {
        newMode = PLAY_MODES.SEQUENTIAL;
      }
      addDebugLog(`재생 모드 변경: ${prevMode} -> ${newMode}`);
      return newMode;
    });
  };

  // 트랙 선택 함수
  const selectTrack = (index) => {
    addDebugLog(`플레이리스트에서 트랙 선택: 인덱스=${index}, 트랙=${tracks[index]}`);
    setCurrentTrackIndex(index);
    setShowPlaylist(false); // Hide playlist after selection
    
  };

  const togglePlaylist = () => {
    setShowPlaylist(!showPlaylist);
  };

  // 모드에 따른 텍스트 반환 함수
  const getPlayModeText = () => {
    switch (playMode) {
      case PLAY_MODES.SEQUENTIAL:
        return '순차 재생';
      case PLAY_MODES.RANDOM:
        return '랜덤 재생';
      case PLAY_MODES.REPEAT_ONE:
        return '한곡 반복';
      default:
        return '순차 재생';
    }
  };

  // 로딩 중이거나 에러 상태 표시
  if (isLoading && tracks.length === 0) {
    return (
      <div className="music-player-container">
        <p style={{color: 'white', fontSize: '12px'}}>음악 로딩 중...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="music-player-container">
        <p style={{color: 'red', fontSize: '12px'}}>{error}</p>
      </div>
    );
  }

  return (
    <div className="music-player-container">
      <div ref={waveformRef} className="waveform"></div>
      <div className="music-controls">
        <button onClick={handlePrev} className="control-button">이전</button>
        <button onClick={togglePlayPause} className="control-button">
          {isPlaying ? '일시정지' : '재생'}
        </button>
        <button onClick={handleNext} className="control-button">다음</button>
        <button 
          onClick={togglePlayMode} 
          className={`control-button mode-button ${
            playMode === PLAY_MODES.RANDOM 
              ? 'random-active' 
              : playMode === PLAY_MODES.REPEAT_ONE 
                ? 'repeat-active' 
                : ''
          }`}
          data-mode={playMode}
        >
          {getPlayModeText()}
        </button>
        <button onClick={togglePlaylist} className="control-button playlist-button">
          목록
        </button>
      </div>
      
      <div className="track-info" style={{fontSize: '10px', color: 'white'}}>
        {tracks.length > 0 && (
          <p>{`제목: ${tracks[currentTrackIndex] || 'Unknown'}`}</p>
        )}
        {!isReady && <p style={{color: '#ffcc00'}}>음악 로딩 중...</p>}
        <p className="play-mode-indicator">
          재생 모드: {getPlayModeText()}
        </p>
      </div>
      
      {showPlaylist && (
        <div className="playlist-container">
          <h4>플레이리스트 ({tracks.length}곡)</h4>
          <ul className="playlist">
            {tracks.map((track, index) => (
              <li 
                key={index} 
                className={index === currentTrackIndex ? 'active-track' : ''}
                onClick={() => selectTrack(index)}
              >
                {track}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MusicPlay;