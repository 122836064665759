// Filename: Clipboard.js

import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill'; // React Quill for WYSIWYG editing
import { Helmet } from 'react-helmet'; // react-helmet import
import 'react-quill/dist/quill.snow.css'; // React Quill styles
import axios from 'axios';
import './Clipboard.css'; 
import { RiFileReduceLine } from "react-icons/ri";
import { RiFileDownloadLine } from "react-icons/ri";
import { TbMailShare } from "react-icons/tb";
import { FaSearch, FaTimes } from "react-icons/fa"; // 검색 아이콘 추가
import Pagination from './components/common/Pagination'; // 페이지네이션 컴포넌트 import

import { recordPageVisit } from './utils/pageVisitTracker';

const SERVER_ROOT = process.env.REACT_APP_SERVER_ROOT || 'http://localhost:3001';

const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const isMobileDevice = isMobile();

let modules;
let formats;

if (isMobileDevice) {
    modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic'],
            [{ align: [] }],  
            ['link', 'image', 'video'],
            ['code-block'],
        ],
    };
    formats = ['header', 'bold', 'italic', 'align', 'link', 'image', 'video', 'code-block'];
} else {
    modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic'],
            [{ align: [] }],  
            ['link', 'image', 'video'],
            ['code-block'],
        ],
    };
    formats = ['header', 'bold', 'italic', 'align', 'link', 'image', 'video', 'code-block'];
}  

// 검색 컴포넌트 추가
function DiarySearch({ onSearch, language }) {
  const [searchTerm, setSearchTerm] = useState('');
  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchTerm);
  };

  const clearSearch = () => {
    setSearchTerm('');
    onSearch('');
  };

  return (
    <div className="diary-search">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder={language === 'KR' ? '제목 또는 내용 검색...' : 'Search title or content...'}
          className="search-input"
        />
        <button type="submit" className="search-button">
          <FaSearch />
        </button>
        {searchTerm && (
          <button type="button" onClick={clearSearch} className="clear-button">
            <FaTimes />
          </button>
        )}
      </form>
    </div>
  );
}

// 자동 저장 상태 표시 컴포넌트
function AutoSaveIndicator({ lastSaved, language }) {
  if (!lastSaved) return null;
  
  return (
    <div className="auto-save-indicator">
      {language === 'KR' 
        ? `자동 저장됨: ${lastSaved.toLocaleTimeString()}` 
        : `Auto-saved at: ${lastSaved.toLocaleTimeString()}`}
    </div>
  );
}

function Clipboard({ language, user, countryCode, userIp  }) {
    const [showSplash, setShowSplash] = useState(true);
    const [activeTab, setActiveTab] = useState('write'); 
    const [tooltipVisible, setTooltipVisible] = useState(null);
    const [tooltipContent, setTooltipContent] = useState('');
    const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리 
    
    // 메모 작성 상태를 상위 컴포넌트에서 관리
    const [draftDiaryTitle, setDraftDiaryTitle] = useState('');
    const [draftDiaryText, setDraftDiaryText] = useState('');
    const [draftSelectedDate, setDraftSelectedDate] = useState('');
    const [draftFiles, setDraftFiles] = useState([]);
    const [lastSaved, setLastSaved] = useState(null);
    const [showDraftNotification, setShowDraftNotification] = useState(false);

    const userId = user ? user.uid : 'unknown'; 
     
    console.log(`SERVER_ROOT: ${SERVER_ROOT}`);

    useEffect(() => {
        recordPageVisit('Clipboard', {
          userId,
          userIp,
          countryCode,
          SERVER_ROOT,
          language,
          isMobileDevice
        });
      }, []);

    // 드래프트 텍스트 압축 함수
    const compressTextForStorage = (text) => {
      if (!text) return '';
      // 너무 긴 텍스트는 일정 길이로 제한
      if (text.length > 10000) {
        return text.substring(0, 10000) + '...';
      }
      return text;
    };
    
    // 컴포넌트 마운트 시 localStorage에서 임시 저장된 내용 로드
    useEffect(() => {
        const loadDraft = () => {
            const savedDraft = localStorage.getItem('diaryDraft');
            if (savedDraft) {
                try {
                    const parsedDraft = JSON.parse(savedDraft);
                    if (parsedDraft.userId === (user?.uid || 'unknown')) {
                        setDraftDiaryTitle(parsedDraft.title || '');
                        setDraftDiaryText(parsedDraft.text || '');
                        setDraftSelectedDate(parsedDraft.date || '');
                        // 파일은 실제 File 객체가 아닌 메타데이터만 저장되었으므로 빈 배열로 초기화
                        setDraftFiles(parsedDraft.filesMeta || []);
                        
                        // 저장된 드래프트가 있다면 알림 표시
                        setShowDraftNotification(true);
                        
                        // 5초 후 알림 숨기기
                        setTimeout(() => {
                            setShowDraftNotification(false);
                        }, 5000);
                    }
                } catch (error) {
                    console.error('Error parsing saved draft:', error);
                }
            }
        };
        
        loadDraft();
    }, [user]);
     
    // Handle splash screen
    useEffect(() => {
        const timer = setTimeout(() => setShowSplash(false), 100);
        return () => clearTimeout(timer);
    }, []); 

    const storedUser = JSON.parse(localStorage.getItem('uk_user'));
    if (!user) {
        user = storedUser;
    }

    const uid = user ? user.uid : 'unknown';
    const displayName = user ? user.displayName : 'Guest';
    const email = user ? user.email : '';
 
    console.log(`user.uid: ${user.uid}`);
    console.log(`user.displayName: ${user.displayName}`);
    console.log(`user.email: ${user.email}`); 

    const handleMouseEnter = (url) => {
        setTooltipContent(url);
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    }; 
    
    // 임시 저장 함수 - DiaryForm에서 호출됨
    const handleUpdateDraft = (title, text, date, files) => {
        setDraftDiaryTitle(title);
        setDraftDiaryText(text);
        setDraftSelectedDate(date);
        setDraftFiles(files);
        
        // 현재 시간을 자동 저장 시간으로 설정
        const currentTime = new Date();
        setLastSaved(currentTime);
        
        // localStorage에 임시 저장
        try {
            // 파일은 메타데이터만 저장 (파일명, 크기 등)
            const filesMeta = files.map(file => ({
                name: file.name,
                size: file.size,
                type: file.type,
                lastModified: file.lastModified
            }));
            
            // 텍스트 압축
            const compressedText = compressTextForStorage(text);
            
            localStorage.setItem('diaryDraft', JSON.stringify({
                userId: uid,
                title,
                text: compressedText,
                date,
                filesMeta,
                lastUpdated: currentTime.toISOString()
            }));
        } catch (error) {
            console.error('Error saving draft to localStorage:', error);
        }
    };
    
    // 임시 저장 내용 클리어 함수 - 성공적인 저장 후 호출됨
    const handleClearDraft = () => {
        setDraftDiaryTitle('');
        setDraftDiaryText('');
        setDraftSelectedDate('');
        setDraftFiles([]);
        localStorage.removeItem('diaryDraft');
    };

    
    
    // 탭 전환 처리 함수 수정 - 내용이 있을 경우 경고 표시
    const handleTabChange = (tab) => {
        // 현재 메모 작성 중이고 내용이 있다면 경고 표시
        // if (activeTab === 'write' && tab === 'view' && 
        //     (draftDiaryTitle || draftDiaryText || draftFiles.length > 0)) {
        //     if (window.confirm(language === 'KR' 
        //         ? '작성 중인 내용이 있습니다. 작성 화면을 나가시겠습니까? 내용은 자동 저장됩니다.' 
        //         : 'You have unsaved changes. Do you want to leave the editor? Your content will be auto-saved.')) {
        //         setActiveTab(tab);
        //     }
        // } else {
        //     setActiveTab(tab);
        // }
        setActiveTab(tab);
    };
    
    // 드래프트 폐기 기능
    const handleDiscardDraft = () => {
        if (window.confirm(language === 'KR' 
            ? '현재 작성 중인 메모를 정말 폐기하시겠습니까?' 
            : 'Are you sure you want to discard the current draft?')) {
            handleClearDraft();
            setShowDraftNotification(false);
        }
    };
    
    return (
        <div className="Diary">
            <Helmet>
            <title>Clipboard - Write Memos and Diary | Doldari</title>
            <meta
                name="description"
                content="Doldari Clipboard is a versatile digital memo pad for saving text, images, and files with seamless organization and preview features."
            />
            <meta
                name="keywords"
                content="clipboard, memo, diary, online, free"
            />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content="Doldari Clipboard" />
            <meta
                property="og:description"
                content="Doldari Clipboard is a versatile digital memo pad for saving text, images, and files with seamless organization and preview features."
            />
            <meta property="og:url" content="https://www.doldari.com/clipboard" />
            <meta property="og:type" content="website" />  
            <meta property="og:image" content="https://www.doldari.com/images/thumbnail_doldari_clipboard.png" /> 
            </Helmet>

            <main>
            <div className='myDiaryContainer'
                onClick={(e) => {
                // 클릭한 요소가 mySites의 자식 요소가 아니라면 스크롤 실행
                if (e.target === e.currentTarget) {
                    window.scrollTo(0, 0);
                }
            }}>  
                <div className='myDiaryTitle'>
                    <div className="Greetings-clipboard">
                        {language === 'KR' ? '간단한 메모, 일기, 파일을 기록하세요.' : 'Write Memo and diary on Doldari.'}
                    </div>
                </div> 
                <div className="tab-buttons">
                <button
                    className={activeTab === 'write' ? 'active' : ''}
                    onClick={() => handleTabChange('write')}
                >
                    {language === 'KR' ? '메모 쓰기' : 'New Memo'}
                </button>
                <button
                    className={activeTab === 'view' ? 'active' : ''}
                    onClick={() => handleTabChange('view')}
                >
                    {language === 'KR' ? '메모 보기' : 'View Diary'}
                </button>
                </div>
                
                {/* {showDraftNotification && activeTab === 'write' && (
                  <div className="draft-notification">
                    <div>
                      {language === 'KR' 
                          ? '이전에 작성 중이던 메모가 복원되었습니다.' 
                          : 'Previous draft has been restored.'}
                    </div>
                    <button 
                      className="discard-draft-btn"
                      onClick={handleDiscardDraft}
                    >
                      {language === 'KR' ? '폐기' : 'Discard'}
                    </button>
                  </div>
                )} */}
                
                <div className='myDiary'> 
                    {activeTab === 'write' && (
                        <DiaryForm 
                            user={{ uid, displayName, email }}
                            language={language} 
                            draftTitle={draftDiaryTitle}
                            draftText={draftDiaryText}
                            draftDate={draftSelectedDate}
                            draftFiles={draftFiles}
                            onUpdateDraft={handleUpdateDraft}
                            onClearDraft={handleClearDraft}
                            lastSaved={lastSaved}
                        />
                    )}
                    
                    {activeTab === 'view' && (
                        <DiaryList 
                            user={{ uid, displayName, email }}
                            language={language}
                        />
                    )}
                </div>
            </div>
            </main>
        </div>
    );
}

function DiaryForm({ user, language, draftTitle, draftText, draftDate, draftFiles, onUpdateDraft, onClearDraft, lastSaved }) {
    const [diaryTitle, setDiaryTitle] = useState(draftTitle || '');
    const [diaryText, setDiaryText] = useState(draftText || '');
    const [voiceFile, setVoiceFile] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    const [files, setFiles] = useState([]); 
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const quillRef = useRef(null);
    
    const containerHeightDefault = isMobileDevice ? "62%" : "85%";
    const quillHeightDefault = isMobileDevice ? "100%" : "89%";

    const [containerHeight, setContainerHeight] = useState(containerHeightDefault);
    const [quillHeight, setQuillHeight] = useState(quillHeightDefault); 

    // 초기 날짜 설정을 위한 함수
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [selectedDate, setSelectedDate] = useState(draftDate || getTodayDate());
    
    // draftFiles가 변경되면 files 상태 업데이트
    useEffect(() => {
        // 새로운 draftFiles가 있고 현재 files가 비어있을 때만 업데이트
        if (draftFiles && draftFiles.length > 0 && files.length === 0) {
            setFiles(draftFiles);
        }
    }, [draftFiles]);
    
    // 컴포넌트가 마운트될 때마다 props의 draft 값이 변경되면 상태 업데이트
    useEffect(() => {
        if (draftTitle !== diaryTitle) setDiaryTitle(draftTitle || '');
        if (draftText !== diaryText) setDiaryText(draftText || '');
        if (draftDate !== selectedDate) setSelectedDate(draftDate || getTodayDate());
    }, [draftTitle, draftText, draftDate]);
    
    // 자동 저장 설정: 내용이 변경될 때마다 상위 컴포넌트에 알림
    useEffect(() => {
        const timer = setTimeout(() => {
            onUpdateDraft(diaryTitle, diaryText, selectedDate, files);
        }, 1000); // 1초 디바운스
        
        return () => clearTimeout(timer);
    }, [diaryTitle, diaryText, selectedDate, files]);

    // 일기 내용 업데이트
    const handleTextChange = (value) => {
        setDiaryText(value);
    
        if (!isMobileDevice && quillRef.current) {
            adjustHeight(value);
        }
    };

    const handleSaveDraft = () => {
        // 현재 내용을 명시적으로 저장
        onUpdateDraft(diaryTitle, diaryText, selectedDate, files);
        
        // 저장 완료 메시지 표시
        setSubmissionStatus(language === 'KR' ? '임시 저장되었습니다.' : 'Draft saved successfully!');
        
        // 3초 후 메시지 숨기기
        setTimeout(() => {
          setSubmissionStatus('');
        }, 1000);
    };

    // 제목 변경 핸들러
    const handleTitleChange = (e) => {
        setDiaryTitle(e.target.value);
    };

    const adjustHeight = (value) => {
        if (!quillRef.current) {
            return;
        }
        
        const lineHeight = 24; // 한 줄의 높이 (Quill에서 텍스트 라인의 평균 높이)
        const padding = 20; // 상하 패딩 합
        const editor = quillRef.current.getEditor();
        const lineCount = editor.getLines().length; // Quill에서 총 라인 수
    
        const buf = lineHeight * lineCount + padding; 
    
        const newHeight = buf > quillHeightDefault ? buf : quillHeightDefault;
    
        const newContainerHeight = newHeight;
     
        // Quill과 container 높이를 동적으로 변경
        setQuillHeight(`${newHeight}px`);
        setContainerHeight(`${newContainerHeight}px`);
    };

    // 파일 업로드 처리
    const handleFileUpload = (e) => {
        const newFiles = [...files, ...Array.from(e.target.files)];
        setFiles(newFiles);
    };
    
    // 파일 삭제 핸들러
    const handleRemoveFile = (index, e) => {
        e.stopPropagation(); // 버블링 방지
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    // 폼 제출 처리
    const handleSubmit = async () => {
        
        if (!user || !selectedDate || !diaryText) {
            setSubmissionStatus(
                language === 'KR' ? '날짜와 내용을 작성해야 합니다.' : 'Date and Text fields must be filled out.'
            );
            return;
        }

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('userId', user.uid);
        formData.append('name', user.displayName);
        formData.append('email', user.email);
        formData.append('selectedDate', selectedDate); 
        formData.append('diaryTitle', diaryTitle);
        formData.append('diaryText', encodeURIComponent(diaryText));
        
        console.log(`user.uid: ${user.uid}`);
        console.log(`user.displayName: ${user.displayName}`);
        console.log(`user.email: ${user.email}`);
        console.log(`selectedDate: ${selectedDate}`);
        console.log(`diaryTitle: ${diaryTitle}`);
        console.log(`diaryText: ${diaryText}`);

        if (voiceFile) formData.append('voiceFile', new File([voiceFile], encodeURIComponent(voiceFile.name)));
        if (photoFile) formData.append('photoFile', new File([photoFile], encodeURIComponent(photoFile.name)));
        
        // 파일 목록에서 실제 File 객체만 추출
        const actualFiles = files.filter(file => file instanceof File || file.size);
        actualFiles.forEach((file) =>
            formData.append('files', new File([file], encodeURIComponent(file.name)))
        );

        try {
            setSubmissionStatus(language === 'KR' ? '업로드가 진행중입니다. 잠시만 기다리세요.' : 'Upload is in progress...');

            await axios.post(`${SERVER_ROOT}/api/diary`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            
            setSubmissionStatus(language === 'KR' ? '일기가 성공적으로 저장되었습니다.' : 'Diary saved successfully!');
            
            // 성공적으로 저장된 경우 임시 저장 내용 클리어
            onClearDraft();
            
            // 폼 초기화
            setDiaryTitle('');
            setDiaryText('');
            setSelectedDate(getTodayDate());
            setVoiceFile(null);
            setPhotoFile(null);
            setFiles([]);
            setIsSubmitting(false);

            // 3초 후 성공 메시지 지우기
            setTimeout(() => {
                setSubmissionStatus('');
            }, 3000);
        } catch (error) {
            console.error('Error saving diary:', error);
            setSubmissionStatus(
                language === 'KR' ? '일기를 저장하는 중 오류가 발생했습니다.' : 'An error occurred while saving the diary.'
            );
            setIsSubmitting(false);
        }

        document.body.scrollIntoView({ behavior: 'smooth' });
    };

    // 드래프트 폐기 기능
    const handleDiscardDraft = () => {
        if (window.confirm(language === 'KR' 
            ? '현재 작성 중인 메모를 정말 폐기하시겠습니까?' 
            : 'Are you sure you want to discard the current draft?')) {
            onClearDraft();
            setDiaryTitle('');
            setDiaryText('');
            setSelectedDate(getTodayDate());
            setFiles([]);
        }
    };

    // 압축 함수
    const compressImage = (imageFile, maxWidth, maxHeight, quality = 0.8) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    // 이미지 크기 조정
                    if (width > maxWidth || height > maxHeight) {
                        if (width / height > maxWidth / maxHeight) {
                            height = (maxWidth / width) * height;
                            width = maxWidth;
                        } else {
                            width = (maxHeight / height) * width;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob(
                        (blob) => {
                            resolve(blob);
                        },
                        'image/jpeg', // 압축된 이미지 형식
                        quality // 품질 (0.8 기본)
                    );
                };
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(imageFile);
        });
    };

    const handlePaste = async (e) => { 
        if (!quillRef.current) return;
        const quill = quillRef.current.getEditor();

        // 현재 커서 위치 저장
        const range = quill.getSelection();
        if (!range || range.index == null) return;

        const clipboardItems = e.clipboardData.items;
        for (const item of clipboardItems) {
            if (item.type.startsWith('image/')) {
                e.preventDefault();
                const imageFile = item.getAsFile();
    
                if (imageFile) {
                    try {
                        console.log('compress image before uploading');
                        const compressedImage = await compressImage(imageFile, 400, 400); // 최대 크기 800x800
                        const reader = new FileReader();
                        reader.onload = () => {
                            quill.insertEmbed(range.index, 'image', reader.result);
                            quill.setSelection(range.index + 1); 
                        };
                        reader.readAsDataURL(compressedImage);
                    } catch (error) {
                        console.error('Error compressing image:', error);
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (quillRef.current) {
            const quillEditor = quillRef.current.getEditor();
            quillEditor.root.addEventListener('paste', handlePaste);

            return () => {
                quillEditor.root.removeEventListener('paste', handlePaste);
            };
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            // iOS 키보드가 사라졌을 때 동작
            if (window.innerHeight === document.documentElement.clientHeight) {
                // 키보드가 닫힌 후 스크롤 복구
                document.body.scrollIntoView({ behavior: 'smooth' });
            }
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const handleBlur = () => {
        document.body.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="DiaryForm">
            <div className='datetitle'>
                <div className="dateSelection"> 
                    <label>{'Date: '}
                        <input 
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}  
                        />
                    </label>
                </div>

                {/* 제목 입력 필드 추가 */}
                <div className="title-input">
                    <label> {'Title: '}
                        <input
                            type="text"
                            value={diaryTitle}
                            onChange={handleTitleChange}
                            placeholder={language === 'KR' ? '제목을 입력하세요' : 'Enter a title'}
                            className="diary-title-input"
                        />
                    </label>
                </div>
            </div>

            <div
                className="textArea"
                style={{
                    height: containerHeight,
                    transition: "height 0.3s ease", // 높이 변경 시 부드러운 애니메이션
                }}
            >
                <ReactQuill
                    ref={quillRef}
                    value={diaryText}
                    onChange={handleTextChange}
                    onBlur={handleBlur}
                    style={{
                        width: "98%",
                        height: quillHeight,
                        marginLeft: "1%",
                        fontSize: "10px",
                    }}
                    modules={modules}
                    formats={formats}
                    theme="snow" 
                    placeholder={language === 'KR' ? ( 
                        "여기에 내용을 기록하세요. 형식은 자유입니다. 파일 크기는 10 MB이하, 한번에 최대 10개까지 업로드 가능합니다." 
                    ) : ( 
                        "Add your text and image clip here... You can paste or insert from your album. Have fun!"
                    )}
                />

                <ul>
                <ul style={{ fontSize: '12px', textAlign: 'left', marginLeft: '20px'}}>
                    {files.map((file, index) => (
                        <li key={index} className="file-list-item">
                            <span>{file.name || file.filename || 'Unknown file'}</span>
                            <button 
                                className="remove-file-btn" 
                                onClick={(e) => handleRemoveFile(index, e)}
                                title={language === 'KR' ? '파일 제거' : 'Remove file'}
                            >
                                ✕
                            </button>
                        </li>
                    ))}
                </ul>
                </ul>
            </div>

            <div style={{display: 'flex', flexDirection: 'row'}}> 
            <div className="fileUpload">
                <label>{language === 'KR' ? '파일 업로드:' : 'Files to upload:'}</label>
                <input type="file" multiple onChange={handleFileUpload} />
            </div>

            
            <button 
                className="discard-draft-btn"
                onClick={handleDiscardDraft}
                style={{ marginRight: '10px' }}
            >
                {language === 'KR' ? '내용 지우기' : 'Clear'}
            </button>

            <button 
                className="save-draft-btn"
                onClick={handleSaveDraft} 
                style={{ marginRight: '10px' }}
            >
                {language === 'KR' ? '임시 저장' : 'Save Draft'}
            </button>

            </div>

            {/* 자동 저장 표시기 */}
            {lastSaved && (
                <div className="auto-save-indicator">
                    {language === 'KR' 
                        ? `자동 저장됨: ${lastSaved.toLocaleTimeString()}` 
                        : `Auto-saved at: ${lastSaved.toLocaleTimeString()}`}
                </div>
            )}

            {submissionStatus && <p className="status-message" style={{ backgroundColor: 'white' }}>{submissionStatus}</p>}
            
            <div className="form-actions" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <button 
                    className="submitButton" 
                    onClick={handleSubmit}
                    disabled={isSubmitting && !submissionStatus}
                    style={{ flex: '1', marginRight: '10px' }}
                >
                {isSubmitting && !submissionStatus
                    ? (language === 'KR' ? '잠시만 기다려주세요' : 'Wait, please') 
                    : (language === 'KR' ? '제출' : 'Submit')
                }
                </button>
                
                
            </div>
        </div>
    );
}

function DiaryList({ user, language }) {
    const [diaries, setDiaries] = useState([]);
    const [filteredDiaries, setFilteredDiaries] = useState([]); // 검색 결과를 저장할 상태
    const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태
    const [selectedDiary, setSelectedDiary] = useState(null);
    const [selectedDiaryId, setSelectedDiaryId] = useState(null);
    const [error, setError] = useState(null);
    const [showViewer, setShowViewer] = useState({});
    const [isLocalData, setIsLocalData] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedDiaryText, setEditedDiaryText] = useState('');
    const [editedFiles, setEditedFiles] = useState([]);
    const [editedDiaryTitle, setEditedDiaryTitle] = useState(''); // 제목 필드 추가
    
    // 페이지네이션 관련 상태 추가
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(isMobileDevice ? 7 : 9); // 페이지당 10개 항목 표시

    const quillRef = useRef(null);
    const selectedDiaryRef = useRef(null);
    
    const containerHeightDefault = isMobileDevice ? "62%" : "85%";
    const quillHeightDefault = isMobileDevice ? "100%" : "100%";

    const [containerHeight, setContainerHeight] = useState(containerHeightDefault);
    const [quillHeight, setQuillHeight] = useState(quillHeightDefault); 

    // 검색 함수
    const handleSearch = (term) => {
        setSearchTerm(term);
        setCurrentPage(1); // 검색 시 첫 페이지로 이동
        
        if (!term.trim()) {
            setFilteredDiaries(diaries); // 검색어가 없으면 전체 목록 표시
            return;
        }
        
        const lowerCaseTerm = term.toLowerCase();
        const results = diaries.filter(diary => {
            const title = diary.titleText ? diary.titleText.toLowerCase() : '';
            const preview = diary.previewText ? 
                (isEncoded(diary.previewText) 
                ? decodeURIComponent(diary.previewText).toLowerCase() 
                : diary.previewText.toLowerCase()) 
                : '';
            
            return title.includes(lowerCaseTerm) || preview.includes(lowerCaseTerm);
        });
        
        setFilteredDiaries(results);
    };

    // 페이지 변경 핸들러
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // 일기 내용 업데이트
    const handleTextChange = (value) => { 
        setEditedDiaryText(value);
    
        // adjustHeight 함수는 quillRef.current가 있을 때만 호출
        if (!isMobileDevice && quillRef.current) {
            adjustHeight(value);
        }
    };

    const adjustHeight = (value) => {
        // quillRef.current가 null인지 확인
        if (!quillRef.current) {
            return; // quillRef.current가 null이면 함수를 빠져나갑니다
        }
    
        const lineHeight = 24; // 한 줄의 높이 (Quill에서 텍스트 라인의 평균 높이)
        const padding = 20; // 상하 패딩 합
        const editor = quillRef.current.getEditor();
        const lineCount = editor.getLines().length; // Quill에서 총 라인 수
        
        const buf = lineHeight * lineCount + padding; 
    
        const newHeight = buf > quillHeightDefault ? buf : quillHeightDefault;
    
        const newContainerHeight = newHeight;
             
        // Quill과 container 높이를 동적으로 변경
        setQuillHeight(`${newHeight}px`);
        setContainerHeight(`${newContainerHeight}px`);
    };

    useEffect(() => {
        if (selectedDiary && selectedDiaryRef.current) {
            // Focus on the selected diary when it changes
            selectedDiaryRef.current.focus();
        }
    }, [selectedDiary]);

    console.log('In DiaryList ---');
    console.log(`user.uid: ${user.uid}`);
    console.log(`user.displayName: ${user.displayName}`);
    console.log(`user.email: ${user.email}`);  
    
    useEffect(() => {
        // user.email이 존재할 때만 fetchDiaryList 호출
        if (user && user.email) {
            fetchDiaryList(user.email);
        }
    }, [user.email]); // user.email이 변경될 때만 실행

    // 검색 결과 업데이트
    useEffect(() => {
        setFilteredDiaries(diaries);
    }, [diaries]);

    // 현재 페이지의 항목 계산
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredDiaries.slice(indexOfFirstItem, indexOfLastItem);

    // Fetch diary list
    const fetchDiaryList = async (email) => {
        try {
            console.log(`Fetching diary list for: ${email}`);
            const response = await axios.get(`${SERVER_ROOT}/api/diary`, { params: { email } });
            setDiaries(response.data);
            setFilteredDiaries(response.data); // 초기 검색 결과는 전체 목록
            setIsLocalData(false);

            // 로컬 스토리지에 데이터 저장
            localStorage.setItem("diaryData", JSON.stringify(response.data));
        } catch (err) {
            console.error("Error fetching diary list:", err);
            setError(language === 'EN' ? 'This is the content from local storage.' : '로컬에 저장된 내용입니다.');

            // 서버에서 데이터를 불러오지 못하면 로컬 데이터 로드
            loadLocalDiaries();
        }
    };

    const fetchDiaryDetails = async (id) => {
        try { 
            const response = await axios.get(`${SERVER_ROOT}/api/diary/${id}`);
            setSelectedDiaryId(id);
            setSelectedDiary(response.data); 
            setIsLocalData(false);

            // 로컬 스토리지에 선택된 일기 저장
            localStorage.setItem(`diary_${id}`, JSON.stringify(response.data));
            console.log(`Saved into local storage: diary_${id}`);

        } catch (err) {
            console.error("Error fetching diary details:", err);
            setError(language === 'EN' ? 'This is the content from local storage.' : '로컬 저장소의 내용입니다.');

            // 로컬 데이터 로드
            loadLocalSelectedDiary(id);
        }
    };

    const loadLocalDiaries = () => {
        const localData = localStorage.getItem("diaryData");
        if (localData) {
            setDiaries(JSON.parse(localData));
            setFilteredDiaries(JSON.parse(localData));
            setIsLocalData(true);
        }
    };

    const loadLocalSelectedDiary = (id) => {
        console.log(`--loadLocalSelectedDiary id: ${id}`);
        const localData = localStorage.getItem(`diary_${id}`);
        if (localData) {
            const diary = JSON.parse(localData);
            if (diary.id === id) {
                setSelectedDiary(diary);
                setSelectedDiaryId(id);
                setIsLocalData(true);
            }
        }
    };

    const handleDiaryClick = (id) => {
        console.log(`--handleDiaryClick id: ${id}`);
        fetchDiaryDetails(id); 
    };

    const handleCloseDetailClick = (id) => {
        setSelectedDiary(null);
        setSelectedDiaryId(null);

        if (selectedDiaryRef.current) {
            selectedDiaryRef.current.focus();
        }
    };

    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.innerText;
    };
    
    const handleVoiceFileDownload = () => {
        const link = document.createElement('a');
        link.href = `${SERVER_ROOT}/uploads/${selectedDiary.voiceFileName}`;
        link.download = selectedDiary.voiceFileName; // 다운로드될 파일 이름 지정
        link.click();
    };

    const handleDelete = async (id) => {
        if (!window.confirm(language === 'EN' ? 'Are you sure you want to delete this record?' : '정말 삭제하시겠습니까?')) {
            return;
        }
    
        try {
            const response = await axios.delete(`${SERVER_ROOT}/api/diary/${id}`);
            if (response.status === 200) {
                alert(language === 'EN' ? 'Record deleted successfully.' : '기록이 성공적으로 삭제되었습니다.');
                // Remove the deleted record from the state
                setDiaries((prevDiaries) => prevDiaries.filter((diary) => diary.id !== id));
                setFilteredDiaries((prevDiaries) => prevDiaries.filter((diary) => diary.id !== id));
                setSelectedDiary(null);
                setSelectedDiaryId(null);
            } else {
                alert(language === 'EN' ? 'Failed to delete the record.' : '기록 삭제에 실패했습니다.');
            }
        } catch (error) {
            console.error('Error deleting record:', error);
            alert(language === 'EN' ? 'An error occurred while deleting the record.' : '기록을 삭제하는 중 오류가 발생했습니다.');
        }
    };
    
    function isEncoded(uri) {
        try {
            return uri !== decodeURIComponent(uri);
        } catch (e) {
            return false; // 디코딩 불가한 경우 URI가 아님
        }
    }
    
    const handlePinchZoom = (event) => {
        if (event.touches.length === 2) {
            const content = event.currentTarget;
    
            const touch1 = event.touches[0];
            const touch2 = event.touches[1];
    
            const distance = Math.sqrt(
                Math.pow(touch2.pageX - touch1.pageX, 2) +
                Math.pow(touch2.pageY - touch1.pageY, 2)
            );
    
            if (!content.lastDistance) {
                content.lastDistance = distance;
    
                // 초기 터치 중심 설정
                const rect = content.getBoundingClientRect();
                const centerX = (touch1.pageX + touch2.pageX) / 2 - rect.left;
                const centerY = (touch1.pageY + touch2.pageY) / 2 - rect.top;
    
                content.style.transformOrigin = `${centerX}px ${centerY}px`;
            }
    
            const scale = distance / content.lastDistance;
            const currentScale = content.scale || 1;
            const newScale = Math.min(Math.max(currentScale * scale, 1), 4); // 1~4배 제한
    
            content.style.transform = `translate(${content.translateX || 0}px, ${content.translateY || 0}px) scale(${newScale})`;
            content.scale = newScale;
            content.lastDistance = distance;
        }
    };
    
    const handleTouchEnd = (event) => {
        const content = event.currentTarget;
        delete content.lastDistance;
    };
    
    const handleDragStart = (event) => {
        if (event.touches.length === 1) {
            const content = event.currentTarget;
            content.dragging = true;
    
            const touch = event.touches[0];
            content.startX = touch.pageX - (content.translateX || 0);
            content.startY = touch.pageY - (content.translateY || 0);
        }
    };
    
    const handleDragMove = (event) => {
        if (event.touches.length === 1) {
            const content = event.currentTarget;
            if (content.dragging) {
                const touch = event.touches[0];
                const translateX = touch.pageX - content.startX;
                const translateY = touch.pageY - content.startY;
    
                content.style.transform = `translate(${translateX}px, ${translateY}px) scale(${content.scale || 1})`;
                content.translateX = translateX;
                content.translateY = translateY;
            }
        }
    };
    
    const handleDragEnd = (event) => {
        const content = event.currentTarget;
        content.dragging = false;
    };
    
    const handleFileDownload = async (filename) => {
        try {
          const fileUrl = `${SERVER_ROOT}/uploads/${filename}`;
          const response = await fetch(fileUrl);
          
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          
          const blob = await response.blob();
          
          // 파일 다운로드를 위한 링크 생성
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = filename; // 다운로드될 파일 이름 설정
          downloadLink.style.display = 'none';
          
          // 링크 클릭을 통해 다운로드 트리거
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          console.error('File download failed:', error);
          alert('Failed to download the file.');
        }
    };
      
    const handleShareDiaryClick = (diaryId) => {
        const recipientEmail = prompt("Enter recipient's email address:");
        if (!recipientEmail) {
            alert("Email address is required!");
            return;
        }
    
        // Backend API 호출
        fetch(`${SERVER_ROOT}/api/share-diary`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                diaryId,
                recipientEmail,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("Diary has been successfully shared!");
                } else {
                    alert(`Failed to share diary: ${data.error}`);
                }
            })
            .catch((error) => {
                console.error("Error sharing diary:", error);
                alert("An error occurred while sharing the diary.");
            });
    };
    
    const handleEditClick = (diary) => {
        setIsEditing(true); // 편집 모드로 전환
        
        // 제목과 내용 설정
        setEditedDiaryTitle(diary.titleText || ''); // 제목 설정
        setEditedDiaryText(
            isEncoded(diary.diaryText)
                ? decodeURIComponent(diary.diaryText)
                : diary.diaryText
        ); // 기존 내용을 상태에 설정
        setEditedFiles(diary.files || []); // 첨부 파일 설정
    };
    
    const handleSaveEdit = async (diaryId) => {
        try {
            const newFiles = editedFiles.filter(
                (editedFile) => !selectedDiary.files.some(
                    (originalFile) => originalFile.filename === (editedFile.name || editedFile.filename)
                )
            );
    
            const filesToDelete = selectedDiary.files.filter(
                (originalFile) => !editedFiles.some(
                    (editedFile) => (editedFile.name || editedFile.filename) === originalFile.filename
                )
            );
    
            console.log(`newFiles: ${newFiles}`);
            console.log(`filesToDelete: ${filesToDelete}`);
            console.log(`user.email: ${user.email}`);
            console.log(`editedDiaryTitle: ${editedDiaryTitle}`); // 제목 로깅
    
            // 업로드할 새 파일 처리
            const formData = new FormData();
            formData.append("diaryText", encodeURIComponent(editedDiaryText));
            formData.append("diaryTitle", editedDiaryTitle); // 제목 추가
            formData.append("email", user.email); 
    
            newFiles.forEach((file) => {
                formData.append("files", file, encodeURIComponent(file.name)); // 새 파일 추가
            });
    
            // 서버에 수정된 내용 저장
            const response = await axios.put(
                `${SERVER_ROOT}/api/diaryFileAdd`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    params: { 
                        id: diaryId,  
                        email: user.email, 
                    } 
                }
            );
    
            if (response.status === 200) {
                alert(language === "KR" ? "수정이 완료되었습니다." : "Diary updated successfully.");
                setIsEditing(false);
                fetchDiaryList(user.email); // 일기 목록 새로고침
                fetchDiaryDetails(diaryId); // 현재 보고 있는 일기 업데이트
            } else {
                throw new Error("Failed to save diary.");
            }
        } catch (error) {
            console.error("Error saving diary:", error);
            alert(language === "KR" ? "수정을 저장하는 중 문제가 발생했습니다." : "Failed to save diary.");
        }
    };
    
    const handleCancelEdit = () => {
        setIsEditing(false);
    };
      
    // 압축 함수
    const compressImage = (imageFile, maxWidth, maxHeight, quality = 0.8) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;
    
                    // 이미지 크기 조정
                    if (width > maxWidth || height > maxHeight) {
                        if (width / height > maxWidth / maxHeight) {
                            height = (maxWidth / width) * height;
                            width = maxWidth;
                        } else {
                            width = (maxHeight / height) * width;
                            height = maxHeight;
                        }
                    }
    
                    canvas.width = width;
                    canvas.height = height;
    
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
    
                    canvas.toBlob(
                        (blob) => {
                            resolve(blob);
                        },
                        'image/jpeg', // 압축된 이미지 형식
                        quality // 품질 (0.8 기본)
                    );
                };
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(imageFile);
        });
    };
    
    const handlePasteRev = async (e) => { 
        if (!quillRef.current) return;
        const quill = quillRef.current.getEditor();

        // 현재 커서 위치 저장
        const range = quill.getSelection();
        if (!range) return; 
    
        const clipboardItems = e.clipboardData.items;
        for (const item of clipboardItems) {
            if (item.type.startsWith('image/')) {
                e.preventDefault();
                const imageFile = item.getAsFile();
    
                if (imageFile) {
                    try {
                        const compressedImage = await compressImage(imageFile, 400, 400); // 최대 크기 800x800
                        const reader = new FileReader();
                        reader.onload = () => {
                            quill.insertEmbed(range.index, 'image', reader.result);
    
                            // 커서 위치 복원: 삽입된 이미지 뒤로 이동
                            quill.setSelection(range.index + 1); 
                        };
                        reader.readAsDataURL(compressedImage);
                    } catch (error) {
                        console.error('Error compressing image:', error);
                    }
                } else if (item.type === "text/plain") {
                    // 텍스트 처리
                    item.getAsString((text) => {
                        quill.insertText(range.index, text);
                        quill.setSelection(range.index + text.length); 
                    });
                } else if (item.type === "text/html") {
                    // HTML 텍스트 처리
                    item.getAsString((html) => {
                        const tempDiv = document.createElement("div");
                        tempDiv.innerHTML = html;
                        const plainText = tempDiv.innerText; // HTML에서 텍스트만 추출
                        quill.insertText(range.index, plainText);
                        quill.setSelection(range.index + plainText.length); 
                    });
                }
            }
        }
    };
    
    useEffect(() => {
        if (isEditing) {
            // 약간의 지연을 두어 ReactQuill이 마운트된 후 quillRef가 설정되도록 합니다
            const timer = setTimeout(() => {
                if (quillRef.current) {
                    const quillEditor = quillRef.current.getEditor();
                    quillEditor.root.addEventListener("paste", handlePasteRev);
                }
            }, 100);
            
            return () => {
                clearTimeout(timer);
                if (quillRef.current) {
                    const quillEditor = quillRef.current.getEditor();
                    quillEditor.root.removeEventListener("paste", handlePasteRev);
                }
            };
        }
    }, [isEditing]); // isEditing 상태에 의존
    
    useEffect(() => {
        const handleResize = () => {
            // iOS 키보드가 사라졌을 때 동작
            if (window.innerHeight === document.documentElement.clientHeight) {
                // 키보드가 닫힌 후 스크롤 복구
                document.body.scrollIntoView({ behavior: 'smooth' });
            }
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const handleBlur = () => {
        document.body.scrollIntoView({ behavior: 'smooth' });
    };
    
    const renderDiaryDetails = (diary) => {
        return (  
            <div className="diary-details"> 
                {isLocalData && <span style={{ color: 'red', fontWeight: 'bold' }}>
                {language === 'EN' ? 'This is the content from local storage.' : '로컬 저장소의 내용입니다.'}
                </span>} 
    
                {isEditing ? (
                    <div className="DiaryForm"> 
                        {/* 제목 입력 필드 추가 */}
                        <div className="title-input">
                            <label> {'Title: '}
                                <input
                                    type="text"
                                    value={editedDiaryTitle}
                                    onChange={(e) => setEditedDiaryTitle(e.target.value)}
                                    placeholder={language === 'KR' ? '제목을 입력하세요' : 'Enter a title'}
                                    className="diary-title-input"
                                />
                            </label>
                        </div>
    
                        <div className="textArea"
                            style={{
                                height: containerHeight,
                                overflow: "visible",
                                transition: "height 0.3s ease", // 높이 변경 시 부드러운 애니메이션
                            }}
                        >  
                            <ReactQuill
                                ref={quillRef}
                                value={editedDiaryText} 
                                onChange={handleTextChange}
                                modules={modules}
                                formats={formats}
                                theme="snow"
                                style={{
                                    width: "98%",
                                    height: quillHeight,
                                    marginLeft: "1%",
                                    fontSize: "10px",
                                }}
                            /> 
                        </div>
     
                        <div>
                            <label>
                                {language === 'KR' ? '첨부 파일 추가:' : 'Add Attachments:'}
                                <input
                                    type="file"
                                    multiple
                                    onChange={(e) =>
                                        setEditedFiles([...editedFiles, ...Array.from(e.target.files)])
                                    }
                                />
                            </label>
                            <ul>
                                {editedFiles.map((file, index) => (
                                    <li key={index} style={{marginLeft: '20px'}}>
                                        <strong
                                        style={{
                                            cursor: 'pointer',
                                            color: 'gray',
                                            textDecoration: 'underline',
                                            fontSize: '12px',
                                            margin: '10px', // 오른쪽에 10px 공간 추가
                                        }}                                            >
                                            {((file.name || file.filename || '').replace(/^[^_]+_[^_]+_/, ''))}
                                        </strong>
                                        
                                        <button
                                            onClick={async () => {
                                                const fileName = file.name || file.filename;
    
                                                try {
                                                    // 서버에 파일 삭제 요청
                                                    const response = await axios.delete(`${SERVER_ROOT}/api/diary-delete-file`, {
                                                        params: { 
                                                            id: diary.id,  
                                                            email: user.email, 
                                                            filename: fileName,
                                                        } 
                                                    });
    
                                                    if (response.status === 200) {
                                                        console.log(`${fileName} deleted successfully.`);
    
                                                        // 클라이언트 상태에서도 파일 제거
                                                        setEditedFiles((prev) => prev.filter((_, i) => i !== index));
                                                    } else {
                                                        console.error(`Failed to delete file: ${fileName}`);
                                                    }
                                                } catch (error) {
                                                    console.error('Error deleting file:', error);
                                                    alert(language === 'KR' ? '파일 삭제 중 문제가 발생했습니다.' : 'An error occurred while deleting the file.');
                                                }
                                            }}
                                        >
                                            {language === 'KR' ? '삭제' : 'Remove'}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
    
                        <div>
                            {/* Save and Cancel buttons */}
                            <button onClick={() => handleSaveEdit(diary.id)}>
                                {language === 'KR' ? '저장' : 'Save'}
                            </button>
                            <button onClick={handleCancelEdit}>
                                {language === 'KR' ? '취소' : 'Cancel'}
                            </button> 
                        </div>
                    </div>
                ) : (
                    <>
                    <div className='memo-topinfo' >
                        <div style={{width: '200px'}}>
                        {new Date(selectedDiary.selectedDate).toLocaleDateString(
                            language !== 'KR' ? 'en-US' : 'ko-KR'
                        )}
                        {' '}
                        {new Date(selectedDiary.currentDateTime).toLocaleTimeString(
                            language !== 'KR' ? 'en-US' : 'ko-KR',
                            { hour: '2-digit', minute: '2-digit' }
                        )}
                        </div>
                        <button
                        className="share-diary-etails-icon"
                        onClick={() => handleShareDiaryClick(diary.id)}
                        style={{ marginRight: '5%', background: 'transparent', color: 'gray'}} // 버튼을 오른쪽 끝으로 정렬
                        > 
                            <TbMailShare style={{width: '20px', height: 'auto'}}/>
                        </button>
    
                        <button onClick={() => handleEditClick(selectedDiary)} style={{width: '60px'}}>
                            {language === 'KR' ? '편집' : 'Edit'}
                        </button>
    
                        <button
                        className="close-details-icon"
                        onClick={() => handleCloseDetailClick(diary.id)}
                        style={{ marginRight: '5%', background: 'transparent', color: 'gray'}} // 버튼을 오른쪽 끝으로 정렬
                        >
                            <RiFileReduceLine style={{width: '20px', height: 'auto'}}/>
                        </button>
                    </div>
    
                    {/* 제목 표시 */}
                    {selectedDiary.titleText && (
                        <div className="diary-title">
                            <h2>{selectedDiary.titleText}</h2>
                        </div>
                    )}
    
                    <div className="diary-text">
                        <div dangerouslySetInnerHTML={{ 
                            __html: 
                            isEncoded(selectedDiary.diaryText) 
                                ? decodeURIComponent(selectedDiary.diaryText) 
                                : selectedDiary.diaryText 
                            }}></div>
                    </div>
                    {selectedDiary.voiceFileName && (
                        <> 
                        <audio controls>
                            <source
                                src={`${SERVER_ROOT}/uploads/${selectedDiary.voiceFileName}`}
                                type="audio/mpeg"
                            />
                            {language === 'EN' ? 'Your browser does not support audio playback.' : '브라우저가 음성 재생을 지원하지 않습니다.'}
                        </audio>  
                        <button onClick={handleVoiceFileDownload} style={{ cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }}>
                            {language === 'EN' ? 'Download' : '다운로드'}
                        </button>  
                        </>
                    )}
                    {selectedDiary.photoFileName && (
                        <div className="diary-photo">
                            <img
                                src={`${SERVER_ROOT}/uploads/${selectedDiary.photoFileName}`}
                                alt={language === 'EN' ? 'Diary Photo' : '일기 사진'}
                            />
                        </div>
                    )}
                    {selectedDiary.files && (
                        <div className='attachedFiles'>
                            <ul style={{width: '100%', height: 'auto', textAlign: 'justify', lineHeight: '1.', padding: '3%'}}>
                                {selectedDiary.files.map((file, index) => {
                                    const fileUrl = `${SERVER_ROOT}/uploads/${file.filename}`;
                                    const fileExtension = (file.filename || file.name).split('.').pop().toLowerCase();
    
                                    return (
                                        <li key={index} style={{marginLeft: '20px'}}>
                                            <strong
                                            style={{
                                                cursor: 'pointer',
                                                color: 'gray',
                                                textDecoration: 'underline',
                                                fontSize: '12px',
                                                margin: '10px', // 오른쪽에 10px 공간 추가
                                            }}
                                            onClick={() => setShowViewer((prevState) => ({ ...prevState, [index]: !prevState[index] }))}
                                            >
                                                {(file.filename || file.name).replace(/^[^_]+_[^_]+_/, '')}
                                            </strong>
                                            <button
                                            onClick={() => handleFileDownload(file.filename)} // 함수 호출
                                            style={{ 
                                                cursor: 'pointer', color: 'gray', border: 'none', 
                                                fontSize: '12px',
                                                background: 'none' 
                                            }}
                                            >
                                            {<RiFileDownloadLine />}
                                            </button>
                                            {showViewer[index] && (
                                                <div className="viewer-container">
                                                    {['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension) && (
                                                        <img
                                                            src={fileUrl}
                                                            alt={file.filename}
                                                            className="zoomable"
                                                            onTouchStart={(event) => {
                                                                handleDragStart(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchMove={(event) => {
                                                                handleDragMove(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchEnd={(event) => {
                                                                handleDragEnd(event);
                                                                handleTouchEnd(event);
                                                            }}
                                                            style={{ maxWidth: '100%', height: 'auto', margin: '10px 0' }}
                                                        />
                                                    )}
                                                    {['mp3', 'wav', 'ogg', 'm4a'].includes(fileExtension) && (
                                                        <audio controls style={{ width: '100%', margin: '10px 0' }}>
                                                            <source 
                                                                src={fileUrl} 
                                                                type={fileExtension === 'm4a' ? 'audio/mp4' : `audio/${fileExtension}`} 
                                                            />
                                                            {language === 'KR'
                                                                ? '브라우저가 오디오 재생을 지원하지 않습니다.'
                                                                : 'Your browser does not support audio playback.'}
                                                        </audio>
                                                    )}
                                                    {['mp4', 'webm', 'ogg', 'mkv', 'mov'].includes(fileExtension) && (
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                                                            <video 
                                                                controls 
                                                                className="zoomable"
                                                                onTouchStart={(event) => {
                                                                    handleDragStart(event);
                                                                    handlePinchZoom(event);
                                                                }}
                                                                onTouchMove={(event) => {
                                                                    handleDragMove(event);
                                                                    handlePinchZoom(event);
                                                                }}
                                                                onTouchEnd={(event) => {
                                                                    handleDragEnd(event);
                                                                    handleTouchEnd(event);
                                                                }}
                                                                style={{ width: '60%', margin: '10px 0' }}
                                                            >
                                                                <source src={fileUrl} type={`video/${fileExtension}`} />
                                                                {language === 'KR'
                                                                    ? '브라우저가 비디오 재생을 지원하지 않습니다.'
                                                                    : 'Your browser does not support video playback.'}
                                                            </video>
                                                        </div>
                                                    )}
                                                    {['pdf'].includes(fileExtension) && (
                                                        <iframe
                                                            src={fileUrl}
                                                            className="zoomable"
                                                            onTouchStart={(event) => {
                                                                handleDragStart(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchMove={(event) => {
                                                                handleDragMove(event);
                                                                handlePinchZoom(event);
                                                            }}
                                                            onTouchEnd={(event) => {
                                                                handleDragEnd(event);
                                                                handleTouchEnd(event);
                                                            }}
                                                            style={{ width: '100%', height: '700px', margin: '10px 0' }}
                                                            frameBorder="0"
                                                            title={file.filename}
                                                        ></iframe>
                                                    )}
                                                    {!['jpg', 'jpeg', 'png', 'gif', 'webp', 'mp3', 'wav', 'ogg', 'm4a', 'mp4', 'webm', 'ogg', 'pdf', 'mkv', 'mov'].includes(
                                                        fileExtension
                                                    ) && (
                                                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                            {file.filename.replace(/^[^_]+_[^_]+_/, '')}  
                                                        </a>
                                                    )}
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    <div className='bottom-buttons' style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
                    <button
                        className="close-details-button"
                        onClick={() => {
                            setSelectedDiary(null); 
                            setSelectedDiaryId(null);
    
                            if (selectedDiaryRef.current) {
                                selectedDiaryRef.current.focus();
                            }
                        }}
                    >
                        {language === 'EN' ? 'Close' : '닫기'}
                    </button>
                    {/* Delete Button */}
                    <button 
                            className="delete-button" 
                            onClick={() => handleDelete(diary.id)}
                        >
                            {language === 'EN' ? 'Delete' : '삭제'}
                        </button>
                    </div>
                  </>
                )}
            </div>
        );
    };
    
    const renderDiaryItem = (diary) => {
        // 검색어 하이라이트 함수
        const highlightText = (text, searchTerm) => {
            if (!searchTerm.trim() || !text) return text;
            
            const lowerText = text.toLowerCase();
            const lowerSearchTerm = searchTerm.toLowerCase();
            
            if (!lowerText.includes(lowerSearchTerm)) return text;
            
            const parts = lowerText.split(lowerSearchTerm);
            const result = [];
            let lastIndex = 0;
            
            parts.forEach((part, i) => {
                // 원래 텍스트에서 해당 부분 추가
                result.push(text.substring(lastIndex, lastIndex + part.length));
                lastIndex += part.length;
                
                // 검색어 부분 추가 (마지막 부분이 아닐 경우)
                if (i < parts.length - 1) {
                    result.push(
                        <span key={i} className="highlight">
                            {text.substring(lastIndex, lastIndex + searchTerm.length)}
                        </span>
                    );
                    lastIndex += searchTerm.length;
                }
            });
            
            return <>{result}</>;
        };
    
        return (
            <div
                key={diary.id}
                className="diary-item"
                onClick={() => handleDiaryClick(diary.id)}
            >
                <div className="diary-item-content">
                    <span>
                        {language === 'EN' 
                            ? new Date(diary.selectedDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                            : new Date(diary.selectedDate).toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric' })
                        }
                    </span>
                    <span>
                        {diary.titleText 
                            ? highlightText(stripHtmlTags(diary.titleText), searchTerm)
                            : highlightText(stripHtmlTags(isEncoded(diary.previewText) 
                                ? decodeURIComponent(diary.previewText) 
                                : diary.previewText), searchTerm)}
                    </span>
        
                    {diary.voiceFileName && (
                        <span>{language === 'EN' ? 'Voice attached' : '음성 첨부됨'}</span>
                    )}
                    {diary.photoFileName && (
                        <span>{language === 'EN' ? 'Photo attached' : '사진 첨부됨'}</span>
                    )}
                </div>
            </div>
        );
        }; 
        
        return (
            <div>
                <div className="diary-list-container">
                    {/* 검색 컴포넌트 추가 */}
                    <DiarySearch 
                        onSearch={handleSearch} 
                        language={language} 
                    />
                    
                    {/* 검색 결과 정보 표시 */}
                    {searchTerm && (
                        <div className="search-results-info">
                            {language === 'KR' 
                                ? `"${searchTerm}" 검색 결과: ${filteredDiaries.length}개 항목`
                                : `Search results for "${searchTerm}": ${filteredDiaries.length} items`
                            }
                        </div>
                    )}
                    
                    <div className="diary-list">
                        {filteredDiaries.length > 0 ? (
                            currentItems.map((diary) => (
                                <React.Fragment key={diary.id}> 
                                    {selectedDiaryId === diary.id ? (
                                        renderDiaryDetails(diary)
                                    ) : (
                                        renderDiaryItem(diary) 
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <p>
                                {searchTerm 
                                    ? (language === 'EN' ? 'No search results found.' : '검색 결과가 없습니다.') 
                                    : (language === 'EN' ? 'No diary entries found.' : '일기가 없습니다.')}
                            </p>
                        )}
                    </div>
                    
                    {/* 페이지네이션 컴포넌트 추가 */}
                    {filteredDiaries.length > itemsPerPage && (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(filteredDiaries.length / itemsPerPage)}
                            onPageChange={handlePageChange}
                            language={language}
                            // maxVisibleButtons={isMobileDevice? 3: 5}
                        />
                    )}
                </div>
            </div>
        );
        }
        
        export default Clipboard;