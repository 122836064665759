// components/manage/DataManageTab.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TableSelector from './TableSelector';
import RecordList from './RecordList';
import RecordForm from './RecordForm';
import RecordActions from './RecordActions';
import Pagination from '../common/Pagination';
import { FaSort, FaSortNumericUp, FaSortNumericDown, FaClock } from 'react-icons/fa';

function DataManageTab({ language, userEmail, SERVER_ROOT, setIsLoading, setStatusMessage }) {
  const [selectedTable, setSelectedTable] = useState('');
  const [fields, setFields] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [newRecord, setNewRecord] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  
  // 페이지네이션 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedRecords, setPaginatedRecords] = useState([]);

  // 정렬 관련 상태 추가
  const [sortField, setSortField] = useState('id'); // 기본 정렬 필드는 id
  const [sortDirection, setSortDirection] = useState('asc'); // 기본 정렬 방향은 오름차순
  
  // 시간대 관련 상태 추가
  const [selectedTimezone, setSelectedTimezone] = useState('Asia/Seoul'); // 기본 시간대는 서울
  const [timezones, setTimezones] = useState([
    { value: 'Asia/Seoul', label: '한국 (KST)' },
    { value: 'UTC', label: '세계 표준시 (UTC)' },
    { value: 'America/New_York', label: '뉴욕 (EST/EDT)' },
    { value: 'America/Los_Angeles', label: '로스앤젤레스 (PST/PDT)' },
    { value: 'Europe/London', label: '런던 (GMT/BST)' },
    { value: 'Europe/Paris', label: '파리 (CET/CEST)' },
    { value: 'Asia/Tokyo', label: '도쿄 (JST)' },
    { value: 'Australia/Sydney', label: '시드니 (AEST/AEDT)' }
  ]);

  // 테이블 필드 조회
  const fetchTableFields = (tableName) => {
    if (!tableName) return;
    
    setIsLoading(true);
    setStatusMessage('테이블 구조를 불러오는 중...');
    
    axios
      .get(`${SERVER_ROOT}/api/tableFields`, { params: { table: tableName } })
      .then((response) => {
        // ID 필드를 포함한 모든 필드 가져오기 (auto_increment와 timestamp만 제외)
        const filteredFields = response.data.filter(
          (field) => field.Type !== 'timestamp'
        );

        setFields(filteredFields);

        setNewRecord(
          filteredFields.reduce((acc, field) => {
            if (field.Type.startsWith('enum')) {
              acc[field.Field] = ''; // Enum 필드는 초기값을 빈 문자열로 설정
            } else {
              acc[field.Field] = '';
            }
            return acc;
          }, {})
        );
        
        setStatusMessage('');
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching table fields:', error);
        setStatusMessage('테이블 구조를 불러오는데 실패했습니다: ' + error.message);
        setIsLoading(false);
      });
  };

  // 테이블 레코드 조회
  const fetchTableRecords = (tableName) => {
    if (!tableName) return;
    
    setIsLoading(true);
    setStatusMessage('데이터를 불러오는 중...');
    
    axios
      .get(`${SERVER_ROOT}/api/tableRecords`, { params: { table: tableName } })
      .then((response) => {
        setRecordList(response.data);
        setCurrentPage(1); // 테이블 변경 시 1페이지로 초기화
        setSortField('id'); // 테이블 변경 시 정렬 필드 초기화
        setSortDirection('asc'); // 테이블 변경 시 정렬 방향 초기화
        setStatusMessage('');
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching table records:', error);
        setStatusMessage('데이터를 불러오는데 실패했습니다: ' + error.message);
        setIsLoading(false);
      });
  };

  // 검색어 필터링 및 정렬, 페이지네이션 적용
  useEffect(() => {
    if (recordList.length > 0) {
      let filtered = recordList;
      
      // 검색어가 있으면 필터링
      if (searchTerm.trim() !== '') {
        filtered = recordList.filter(record => 
          Object.values(record).some(value => 
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
      
      // 선택된 필드로 정렬
      const sorted = [...filtered].sort((a, b) => {
        const valA = a[sortField] === null || a[sortField] === undefined ? '' : a[sortField];
        const valB = b[sortField] === null || b[sortField] === undefined ? '' : b[sortField];
        
        if (typeof valA === 'string' && typeof valB === 'string') {
          return sortDirection === 'asc' 
            ? valA.localeCompare(valB) 
            : valB.localeCompare(valA);
        }
        
        return sortDirection === 'asc' 
          ? valA - valB 
          : valB - valA;
      });
      
      setFilteredRecords(sorted);
      
      // 총 페이지 수 계산
      const total = Math.ceil(sorted.length / recordsPerPage);
      setTotalPages(total);
      
      // 현재 페이지가 총 페이지 수보다 크면 1페이지로 이동
      if (currentPage > total && total > 0) {
        setCurrentPage(1);
      }
      
      // 현재 페이지에 표시할 레코드 계산
      const startIndex = (currentPage - 1) * recordsPerPage;
      const paginatedData = sorted.slice(startIndex, startIndex + recordsPerPage);
      setPaginatedRecords(paginatedData);
    } else {
      setPaginatedRecords([]);
      setTotalPages(0);
    }
  }, [searchTerm, recordList, currentPage, recordsPerPage, sortField, sortDirection]);

  const handleTableSelect = (tableName) => {
    setSelectedTable(tableName);
    if (tableName) {
      fetchTableFields(tableName);
      fetchTableRecords(tableName);
    }
    setIsEditing(false);
    setIsAdding(false);
    setSearchTerm('');
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRecordsPerPageChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setRecordsPerPage(value);
      setCurrentPage(1); // 레코드 수 변경 시 1페이지로 초기화
    }
  };

  // 정렬 필드 변경 처리
  const handleSortFieldChange = (e) => {
    setSortField(e.target.value);
  };

  // 정렬 방향 토글 처리
  const toggleSortDirection = () => {
    setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };
  
  // 시간대 변경 처리
  const handleTimezoneChange = (e) => {
    setSelectedTimezone(e.target.value);
  };

  // 레코드 추가
  const handleAddRecord = () => {
    // 필수 필드 검증
    const missingFields = fields.filter(field => 
      field.Null === 'NO' && 
      field.Extra !== 'auto_increment' && 
      (!newRecord[field.Field] || newRecord[field.Field] === '')
    );
    
    if (missingFields.length > 0) {
      setStatusMessage(`다음 필드를 입력해주세요: ${missingFields.map(f => f.Field).join(', ')}`);
      setTimeout(() => setStatusMessage(''), 5000);
      return;
    }
    
    setIsLoading(true);
    setStatusMessage('레코드를 추가하는 중...');
    
    axios
      .post(`${SERVER_ROOT}/api/addRecord`, {
        table: selectedTable,
        newRecord,
      })
      .then(() => {
        fetchTableRecords(selectedTable);
        setNewRecord(fields.reduce((acc, field) => ({ ...acc, [field.Field]: '' }), {}));
        setIsAdding(false);
        setStatusMessage('레코드가 성공적으로 추가되었습니다.');
        setTimeout(() => setStatusMessage(''), 3000);
      })
      .catch((error) => {
        console.error('Error adding record:', error);
        setStatusMessage('레코드 추가에 실패했습니다: ' + error.message);
        setIsLoading(false);
      });
  };

  // 레코드 삭제
  const handleDeleteRecord = () => {
    if (!selectedRecord) {
      setStatusMessage(language === 'KR' ? '레코드를 선택하세요.' : 'Please select a record.');
      setTimeout(() => setStatusMessage(''), 3000);
      return;
    }

    if (window.confirm(language === 'KR' ? '정말로 이 레코드를 삭제하시겠습니까?' : 'Are you sure you want to delete this record?')) {
      setIsLoading(true);
      setStatusMessage('레코드를 삭제하는 중...');
      
      axios
        .delete(`${SERVER_ROOT}/api/deleteRecord`, {
          data: { table: selectedTable, id: selectedRecord.id },
        })
        .then(() => {
          fetchTableRecords(selectedTable);
          setSelectedRecord(null);
          setIsEditing(false);
          setStatusMessage('레코드가 성공적으로 삭제되었습니다.');
          setTimeout(() => setStatusMessage(''), 3000);
        })
        .catch((error) => {
          console.error('Error deleting record:', error);
          setStatusMessage('레코드 삭제에 실패했습니다: ' + error.message);
          setIsLoading(false);
        });
    }
  };

  // 레코드 수정
  const handleModifyRecord = () => {
    if (!selectedRecord) {
      setStatusMessage(language === 'KR' ? '레코드를 선택하세요.' : 'Please select a record.');
      setTimeout(() => setStatusMessage(''), 3000);
      return;
    }

    // 필수 필드 검증
    const missingFields = fields.filter(field => 
      field.Null === 'NO' && 
      field.Extra !== 'auto_increment' && 
      (!selectedRecord[field.Field] || selectedRecord[field.Field] === '')
    );
    
    if (missingFields.length > 0) {
      setStatusMessage(`다음 필드를 입력해주세요: ${missingFields.map(f => f.Field).join(', ')}`);
      setTimeout(() => setStatusMessage(''), 5000);
      return;
    }
    
    setIsLoading(true);
    setStatusMessage('레코드를 수정하는 중...');
    
    axios
      .put(`${SERVER_ROOT}/api/modifyRecord`, {
        table: selectedTable,
        id: selectedRecord.id,
        updatedRecord: selectedRecord,
      })
      .then(() => {
        fetchTableRecords(selectedTable);
        setSelectedRecord(null);
        setIsEditing(false);
        setStatusMessage('레코드가 성공적으로 수정되었습니다.');
        setTimeout(() => setStatusMessage(''), 3000);
      })
      .catch((error) => {
        console.error('Error modifying record:', error);
        setStatusMessage('레코드 수정에 실패했습니다: ' + error.message);
        setIsLoading(false);
      });
  };

  const handleEditRecord = () => {
    if (!selectedRecord) {
      setStatusMessage(language === 'KR' ? '레코드를 선택하세요.' : 'Please select a record.');
      setTimeout(() => setStatusMessage(''), 3000);
      return;
    }

    setIsEditing(true);
    setIsAdding(false);
  };

  const handleAddButtonClick = () => {
    setIsAdding(true);
    setIsEditing(false);
    setSelectedRecord(null);
  };

  return (
    <div className="datamanage">
      <TableSelector 
        selectedTable={selectedTable}
        onSelectTable={handleTableSelect}
        language={language}
      />

      {selectedTable && (
        <>
          <RecordActions 
            onAdd={handleAddButtonClick}
            onEdit={handleEditRecord}
            onDelete={handleDeleteRecord}
            searchTerm={searchTerm}
            onSearchChange={(e) => setSearchTerm(e.target.value)}
            selectedRecord={selectedRecord}
            isLoading={false}
            language={language}
          />

          <div className="pagination-controls">
            {/* 정렬 컨트롤 추가 */}
            <div className="sort-controls">
              <label htmlFor="sortField">
                {language === 'KR' ? '정렬 기준:' : 'Sort by:'}
              </label>
              <select
                id="sortField"
                value={sortField}
                onChange={handleSortFieldChange}
              >
                {fields.map(field => (
                  <option key={field.Field} value={field.Field}>
                    {field.Field}
                  </option>
                ))}
              </select>
              <button 
                className="sort-direction-button" 
                onClick={toggleSortDirection}
                title={sortDirection === 'asc' 
                  ? (language === 'KR' ? '오름차순' : 'Ascending') 
                  : (language === 'KR' ? '내림차순' : 'Descending')}
              >
                {sortDirection === 'asc' ? (language === 'KR' ? '오름' : 'UP')  : (language === 'KR' ? '내림' : 'DN')}
              </button>
            </div>
            
            {/* 시간대 선택 컨트롤 추가 */}
            <div className="timezone-controls">
              <FaClock className="timezone-icon" />
              <label htmlFor="timezone">
                {language === 'KR' ? '시간대:' : 'Timezone:'}
              </label>
              <select
                id="timezone"
                value={selectedTimezone}
                onChange={handleTimezoneChange}
              >
                {timezones.map(tz => (
                  <option key={tz.value} value={tz.value}>
                    {tz.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="records-per-page">
              <label htmlFor="recordsPerPage">
                {language === 'KR' ? '페이지당 레코드 수:' : 'Records per page:'}
              </label>
              <input
                id="recordsPerPage"
                type="number"
                min="1"
                max="100"
                value={recordsPerPage}
                onChange={handleRecordsPerPageChange}
              />
            </div>
            <div className="record-stats">
              {filteredRecords.length > 0 ? (
                language === 'KR' 
                  ? `전체 ${filteredRecords.length}개 중 ${(currentPage - 1) * recordsPerPage + 1}-${Math.min(currentPage * recordsPerPage, filteredRecords.length)}개 표시`
                  : `Showing ${(currentPage - 1) * recordsPerPage + 1}-${Math.min(currentPage * recordsPerPage, filteredRecords.length)} of ${filteredRecords.length} records`
              ) : (
                language === 'KR' ? '데이터 없음' : 'No data'
              )}
            </div>
          </div>

          <RecordList 
            fields={fields}
            records={paginatedRecords}
            selectedRecord={selectedRecord}
            onSelectRecord={setSelectedRecord}
            sortField={sortField}
            sortDirection={sortDirection}
            selectedTimezone={selectedTimezone}
            onSort={(field) => {
              if (field === sortField) {
                toggleSortDirection();
              } else {
                setSortField(field);
                setSortDirection('asc');
              }
            }}
            language={language}
          />

          {/* 페이지네이션 컨트롤 */}
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              language={language}
            />
          )}

          {isAdding && (
            <RecordForm
              formType="add"
              fields={fields}
              record={newRecord}
              onRecordChange={setNewRecord}
              onSubmit={handleAddRecord}
              onCancel={() => setIsAdding(false)}
              language={language}
              isLoading={false}
            />
          )}

          {isEditing && selectedRecord && (
            <RecordForm
              formType="edit"
              fields={fields}
              record={selectedRecord}
              onRecordChange={setSelectedRecord}
              onSubmit={handleModifyRecord}
              onCancel={() => setIsEditing(false)}
              language={language}
              isLoading={false}
            />
          )}
        </>
      )}
    </div>
  );
}

export default DataManageTab;