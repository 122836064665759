import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// 개발 환경에서 불필요한 로그 제거
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {}; // 실제 에러 로깅 서비스를 사용한다면 이 부분 주석 처리
}

// 전역 에러 핸들링
window.addEventListener('error', (event) => {
  // 여기에 에러 로깅 로직 추가 가능
  console.error('전역 에러 발생:', event.error);
  // 실제 환경에서는 적절한 에러 추적 서비스에 전송
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // 개발 중에만 StrictMode 활성화, 프로덕션에서는 비활성화 가능
  process.env.NODE_ENV === 'development' ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  )
);

// 성능 측정
reportWebVitals(process.env.NODE_ENV === 'development' ? console.log : undefined);