// components/manage/RecordForm.js
import React from 'react';
import { parseEnumValues } from '../../utils/formatters';

function RecordForm({ 
  formType, 
  fields, 
  record, 
  onRecordChange, 
  onSubmit, 
  onCancel, 
  language, 
  isLoading 
}) {
  const isAdd = formType === 'add';
  
  return (
    <div className="form-container">
      <h3>
        {isAdd 
          ? (language === 'KR' ? '새 레코드 추가' : 'Add New Record')
          : (language === 'KR' ? '레코드 수정' : 'Edit Record')}
      </h3>
      <div className="field-inputs">
        {fields.map((field) => (
          <div key={field.Field} className="input-group">
            <label>
              {field.Field} {field.Null === 'NO' && <span className="required">*</span>}
            </label>
            {field.Type.startsWith('enum') ? (
              <select
                value={record[field.Field] || ''}
                onChange={(e) =>
                  onRecordChange({ ...record, [field.Field]: e.target.value })
                }
              >
                <option value="">
                  {language === 'KR' ? '선택하세요' : 'Select'}
                </option>
                {parseEnumValues(field.Type).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : field.Type.includes('text') ? (
              <textarea
                value={record[field.Field] || ''}
                onChange={(e) =>
                  onRecordChange({ ...record, [field.Field]: e.target.value })
                }
                rows="3"
              />
            ) : (
              <input
                type="text"
                value={record[field.Field] || ''}
                onChange={(e) =>
                  onRecordChange({ ...record, [field.Field]: e.target.value })
                }
              />
            )}
          </div>
        ))}
      </div>
      <div className="form-buttons">
        <button 
          className="action-button save" 
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isAdd 
            ? (language === 'KR' ? '추가' : 'Add')
            : (language === 'KR' ? '저장' : 'Save')}
        </button>
        <button 
          className="action-button cancel" 
          onClick={onCancel}
          disabled={isLoading}
        >
          {language === 'KR' ? '취소' : 'Cancel'}
        </button>
      </div>
    </div>
  );
}

export default RecordForm;